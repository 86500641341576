import { createStore, ModuleTree } from "vuex";
import authModule from "@/modules/Auth/vuex";
import agentModule from "@/modules/Agent/vuex";
import organizationModule from "@/modules/Organization/vuex";
import soaModule from "@/modules/SOA/vuex";
import permissionsModule from "@/modules/Permissions/vuex";
import sectorModule from "@/modules/Sector/vuex";
import financialProfileModule from "@/modules/FinancialProfile/vuex";
import myBookingsModule from "@/modules/MyBookings/vuex";
import paymentsModule from "@/modules/Payments/vuex";
import creditLimitManagementModule from "@/modules/CreditLimitManagement/vuex";
import dashboardModule from "@/modules/Dashboard/vuex";
import sabreSegmentModule from "@/modules/SabreSegment/vuex";
import newsModule from "@/modules/News/vuex";
import salesModule from "@/modules/Sales/vuex";
import profileModule from "@/modules/Profile/vuex";
import hotelModule from "@/modules/Hotel/vuex";
import flightIssuanceModule from "@/modules/FlightIssuance/vuex";
import flightModule from "@/modules/Flight/vuex";
import flightBookingModule from "@/modules/Booking/vuex";
import fdgModule from "@/modules/FDG/vuex";
import flightRefundsModule from "@/modules/FlightRefunds/vuex";
import DealsAndRefundModule from "@/modules/Deals/vuex";
import FITModule from "@/modules/FIT/vuex";
import { StoreState } from "./type";

const modules: ModuleTree<StoreState> = {
  authModule,
  agentModule,
  organizationModule,
  soaModule,
  permissionsModule,
  sectorModule,
  financialProfileModule,
  myBookingsModule,
  paymentsModule,
  creditLimitManagementModule,
  dashboardModule,
  sabreSegmentModule,
  newsModule,
  salesModule,
  profileModule,
  hotelModule,
  flightIssuanceModule,
  flightModule,
  flightBookingModule,
  fdgModule,
  flightRefundsModule,
  DealsAndRefundModule,
  FITModule,
};

const store = createStore<StoreState>({
  modules,
});

export default store;
