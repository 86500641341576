import { parseIntoCurrentDateIgnoringTimezone } from "@/ag-portal-common/utils/helpers";
import {
  format,
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
} from "date-fns";

export interface Durations {
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const DURATION_BY_SECONDS: Durations = {
  years: 31536000,
  months: 2592000,
  weeks: 604800,
  days: 86400,
  hours: 3600,
  minutes: 60,
  seconds: 1,
};

export class TimeUtility {
  static parsedFlightTimeorDate(datetime: Date, _format = "HH:mm") {
    const formattedDate = parseIntoCurrentDateIgnoringTimezone(
      datetime.toString()
    );
    return datetime ? format(formattedDate, _format) : "";
  }

  static teTimeIntoLocalDate(date: Date, _format = "HH:mm") {
    const formattedDate = parseIntoCurrentDateIgnoringTimezone(date.toString());
    return format(formattedDate, _format);
  }

  public getDiffernceInDays(start: Date, end: Date) {
    const diffInDays = differenceInDays(end, start);
    return diffInDays;
  }

  public getDurationByStartToEnd(from: Date, to: Date) {
    const diffInMinutes = differenceInMinutes(from, to);
    return diffInMinutes;
  }

  static getTotalDurationInText(from: Date, to: Date) {
    const startDate = new Date(from);
    const endDate = new Date(to);

    const diffInDays = differenceInDays(endDate, startDate);
    const diffInHours = differenceInHours(endDate, startDate) % 24;
    const diffInMinutes = differenceInMinutes(endDate, startDate) % 60;

    const daysStr =
      diffInDays > 0 ? `${diffInDays} day${diffInDays > 1 ? "s" : ""}` : "";
    const hoursStr =
      diffInHours > 0 ? `${diffInHours} hr${diffInHours > 1 ? "s" : ""}` : "";
    const minutesStr =
      diffInMinutes > 0
        ? `${diffInMinutes} min${diffInMinutes > 1 ? "s" : ""}`
        : "";

    return `${daysStr} ${hoursStr} ${minutesStr}`.trim();
  }

  static getDurationInTextByMinutes(minutes: number): string {
    const days = Math.floor(minutes / (24 * 60));
    const remainingMinutes = minutes % (24 * 60);
    const hours = Math.floor(remainingMinutes / 60);
    const finalMinutes = remainingMinutes % 60;

    const daysStr = days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "";
    const hoursStr = hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""}` : "";
    const minutesStr =
      finalMinutes > 0
        ? `${finalMinutes} min${finalMinutes > 1 ? "s" : ""}`
        : "";

    return `${daysStr} ${hoursStr} ${minutesStr}`.trim();
  }
}
