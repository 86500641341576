import {
  FinancialProfile,
  Organization,
  Sector,
  User,
} from "@/modules/Auth/types";
import { NOTIFICATION_MESSAGES } from "../constants/notificationMessages";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { NOTIFICATION_TYPES } from "../enums/NOTIFICATION_TYPES";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "../eventBusses/notification";
import { INotification } from "../interfaces/notification.interface";
import storageService from "../services/storage.service";
import { setThemeColor } from "./colortheme";
import store from "@/store";
import { AUTH_MUTATIONS } from "@/modules/Auth/vuex/mutations";
import { PERMISSIONS } from "../enums/PERMISSIONS";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { USER_ROLES } from "../enums/USER_ROLES";
import {
  BaggageInfo,
  DealDiscount,
  DiscountType,
  FareOption,
  FlightOption,
  ReturnFlightPair,
} from "@/ag-flight-components/types";
import notificationService from "../services/notification.service";
import analyticsService from "@/analytic.service";
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { getGrossFare } from "@/ag-flight-components/utils";
import {
  AmountType,
  FareType,
  TemperFare,
} from "@/modules/FlightSearchResult/types";
import { PATH } from "../constants/path";
import { useIsMobile } from "@aeroglobe/ag-core-ui";

const UTILS = {
  setThemeColor,

  notify(description: string | null, type = NOTIFICATION_TYPES.INFO): void {
    const data: INotification = {
      type: type,
      description: description || NOTIFICATION_MESSAGES.DEFAULT,
    };

    notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, data);
  },

  updateUser(user: User) {
    storageService.setItem(STORAGE_KEYS.USER, JSON.stringify(user));

    const organization = user.agent_user_data.organization;

    setThemeColor(organization);

    this.updateOrganization(organization);

    store.commit(AUTH_MUTATIONS.UPDATE_USER, user);
  },

  updateAuthTokens(access: string, refresh: string) {
    storageService.setItem(STORAGE_KEYS.ACCESS_TOKEN, access, false);
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh, false);

    store.commit(AUTH_MUTATIONS.UPDATE_AUTH_TOKENS, { access, refresh });
  },

  updatePermissions(permissions: PERMISSIONS[]) {
    storageService.setItem(
      STORAGE_KEYS.PERMISSIONS,
      JSON.stringify(permissions)
    );

    store.commit(AUTH_MUTATIONS.PERMISSIONS, permissions);
  },

  updateOrganization(organization?: Organization) {
    storageService.setItem(
      STORAGE_KEYS.ORGANIZATION,
      JSON.stringify(organization)
    );

    if (organization) {
      storageService.setItem(
        STORAGE_KEYS.FINANCIAL_PROFILES,
        JSON.stringify(organization.financial_profiles)
      );
    } else {
      storageService.removeItem(STORAGE_KEYS.ORGANIZATION);
      storageService.removeItem(STORAGE_KEYS.FINANCIAL_PROFILES);
    }

    store.commit(AUTH_MUTATIONS.UPDATE_ORGANIZATION, organization);
  },

  updateFinancialProfiles(financialProfiles: FinancialProfile[]) {
    storageService.setItem(
      STORAGE_KEYS.FINANCIAL_PROFILES,
      JSON.stringify(financialProfiles)
    );

    store.commit(AUTH_MUTATIONS.UPDATE_FINANCIAL_PROFILES, financialProfiles);
  },

  updateSectors(sectors: Sector[]) {
    storageService.setItem(STORAGE_KEYS.SECTORS, JSON.stringify(sectors));

    store.commit(AUTH_MUTATIONS.UPDATE_SECTORS, sectors);
  },

  getCurrency() {
    const currency = store.getters[AUTH_GETTERS.CURRENCY] as string | null;

    return currency;
  },

  isUser(...roles: USER_ROLES[]) {
    const user = store.getters[AUTH_GETTERS.USER_ROLE] as USER_ROLES | null;

    return user ? roles.includes(user) : false;
  },

  mapSectorInFinancialProfiles(
    sector: string,
    financialProfiles: FinancialProfile[]
  ): FinancialProfile[] {
    let tempFp = financialProfiles;

    tempFp = tempFp.map((fp) => {
      return { ...fp, sector };
    });

    return tempFp;
  },

  parseDiscountLabel(data: DealDiscount | null): string {
    if (!data) {
      return "";
    }

    const {
      discount_type,
      discount_on_gross_fare,
      discount_amount,
      discount_currency,
    } = data;

    const amount =
      discount_type === DiscountType.PERCENTAGE
        ? `${discount_amount}%`
        : `${discount_amount}${discount_currency}`;

    const fareType = discount_on_gross_fare ? "Gross Fare" : "Base Fare";
    return `Applied on ${fareType} with ${amount} discount`;
  },

  getBaggageInfo(baggage_info?: BaggageInfo | null): string {
    if (!baggage_info) {
      return "No Baggage";
    }

    const { pieces, weight, unit } = baggage_info;

    if (pieces > 0) {
      return `(${pieces}x) ${weight > 0 ? `${weight}${unit}` : "(As per AP)"}`;
    }

    if (pieces === 0 && weight === 0) {
      return "No Baggage";
    }

    return `${weight}${unit}`;
  },

  getFormattedCurrency(amount: number) {
    const currency = this.getCurrency();

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ?? "PKR",
      minimumFractionDigits: 0,
    }).format(Number(amount));
  },

  addFlightToQoute(flightItem: FlightOption): void {
    const existingQuotationsString = localStorage.getItem("quotations");
    const existingQuotations: any[] = existingQuotationsString
      ? JSON.parse(existingQuotationsString)
      : [];

    const isFlightAlreadyAdded = existingQuotations.some((quotation) => {
      return (
        quotation.airline_code == flightItem.flight_numbers[0] &&
          quotation.from_time ==
            TimeUtility.parsedFlightTimeorDate(flightItem.departure_time),
        quotation.date.departureDate == flightItem.departure_time
      );
    });

    const segments = flightItem.fare_options[0].segments;

    if (!isFlightAlreadyAdded) {
      if (existingQuotations.length < 10) {
        const data = {
          airline_logo: flightItem.airline.logo,
          airline_code: flightItem.flight_numbers[0],
          duration: TimeUtility.getDurationInTextByMinutes(flightItem.duration),
          from_destination: segments[0].origin.iata_code,
          from_time: TimeUtility.parsedFlightTimeorDate(
            flightItem.departure_time
          ),
          to_time: TimeUtility.parsedFlightTimeorDate(flightItem.arrival_time),
          to_destination: segments[segments.length - 1].destination.iata_code,
          airline_name: flightItem.name,
          rdbs: flightItem.fare_options,
          date: flightItem.departure_time,
        };

        existingQuotations.push(data);
        localStorage.setItem("quotations", JSON.stringify(existingQuotations));
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Flight Added To Quotations";
        notificationService.triggerNotification();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Delete few quotations to add more";
        notificationService.triggerNotification();
      }
    } else {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = "Flight already exists in quotations";
      notificationService.triggerNotification();
    }

    analyticsService.logActionEvent(
      FLIGHTS_ANALYTICS_COMMON_EVENTS.ADD_TO_QUOTE,
      {
        dataAdded: localStorage.getItem("quotations"),
      }
    );
  },

  addPairToQoute(pair: ReturnFlightPair): void {
    const existingQuotationsString = localStorage.getItem("Quotations-Return");
    const existingQuotations: ReturnFlightPair[] = existingQuotationsString
      ? JSON.parse(existingQuotationsString)
      : [];
    const flightName = pair.departure.flight_option
      ? pair.departure.flight_option.name
      : "";
    const firstRBD = pair.departure.fare_option.rbd;
    const secondRBD = pair.arrival.fare_option.rbd;

    const isFlightAlreadyAdded = existingQuotations.some(
      (pair: ReturnFlightPair) => {
        return (
          pair.departure.flight_option?.name == flightName &&
          pair.departure.fare_option.rbd == firstRBD &&
          pair.arrival.fare_option.rbd == secondRBD
        );
      }
    );

    if (!isFlightAlreadyAdded) {
      if (existingQuotations.length < 10) {
        existingQuotations.push(pair);
        localStorage.setItem(
          "Quotations-Return",
          JSON.stringify(existingQuotations)
        );
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Flight Added To Quotations";
        notificationService.triggerNotification();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Delete few quotations to add more";
        notificationService.triggerNotification();
      }
    } else {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = "Flight already exists in quotations";
      notificationService.triggerNotification();
    }

    analyticsService.logActionEvent(
      FLIGHTS_ANALYTICS_COMMON_EVENTS.ADD_TO_QUOTE,
      {
        dataAdded: localStorage.getItem("Quotations-Return"),
      }
    );
  },

  calculateFlightPrice(fareOption: FareOption, temperFare: TemperFare): number {
    const fareCalculationAmount = temperFare.amount;
    const fareType = temperFare.fareType;
    const amountType = temperFare.amountType;

    if (!fareCalculationAmount) {
      return getGrossFare(fareOption);
    }

    let fare = getGrossFare(fareOption);
    let amountToAdd = fareCalculationAmount;
    let tax = 0;

    if (fareType === FareType.BASE) {
      fare = Number(fareOption.price.base_fare.value);
      tax = Number(fareOption.price.tax.value);
    }

    if (amountType === AmountType.PERCENTAGE) {
      amountToAdd = (fare / 100) * fareCalculationAmount;
    }

    return fare + amountToAdd + tax;
  },

  flightSearchResultsPath(): string {
    const oldDesingPath = PATH.FLIGHTS_SEARCH_RESULT;
    const newDesingPath = PATH.FLIGHTS_SEARCH_RESULT_V1;
    const { isMobile } = useIsMobile();

    return isMobile.value ? oldDesingPath : newDesingPath;
  },

  toTitleCase(input: string): string {
    const convertedInput = input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return convertedInput;
  },
};

export default UTILS;
