import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fa1caac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!

  return (_ctx.$store.getters.isSalesMyTeamLoading)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_MCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_AgIconInfoBar, {
              title: "My Team",
              class: "section-title",
              mPrepandIcon: "m-my-team"
            })
          ]),
          _: 1
        }),
        (_ctx.myTeam.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myTeam, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "my-teams"
                }, [
                  _createVNode(_component_MAccordion, { class: "flight_accordion_wrap" }, {
                    title: _withCtx(() => [
                      _createVNode(_component_AgIconInfoBar, {
                        title: _ctx.renderSectoName(item.sector_name),
                        class: "section-title",
                        mPrepandIcon: "m-my-team"
                      }, null, 8, ["title"])
                    ]),
                    content: _withCtx(() => [
                      _createVNode(_component_MDataTable, {
                        headers: _ctx.headers,
                        data: item.my_team_members,
                        "item-per-page": 30,
                        "has-search": false,
                        simpleDataTable: true
                      }, null, 8, ["headers", "data"])
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_MCard, {
              key: 1,
              class: "m-card"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ResultNotFound, { title: "No Sales Team Found" })
              ]),
              _: 1
            }))
      ], 64))
}