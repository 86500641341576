<template>
  <div class="heading">
    <h5>Select Information</h5>
    <p>
      You can access information via your financial profile or search by email
    </p>
  </div>

  <div class="search-bar-card">
    <form @submit.prevent="handleGetPriceDetail">
      <AgRow>
        <AgColumn sm="12" md="6" lg="6" cols="12">
          <MFinancialProfileCombobox
            v-model:inputValue="financialProfileInput"
            label="Financial Profiles"
            itemValue="value"
            itemLabel="label"
            :disabled="isFinancialProfileFetching"
            :options="financialProfileOptions"
          />
        </AgColumn>
        <AgColumn sm="12" md="6" lg="6" cols="12">
          <MTextfield
            type="email"
            v-model:inputValue="emailInput"
            label="Search Email"
          />
        </AgColumn>
        <AgColumn sm="12" md="6" lg="6" cols="12">
          <MButton
            class="get-price-detail-btn"
            type="filled"
            :disabled="getPriceBtnDisabled()"
            :isLoading="fetchingPriceDetails"
            behaviour="submit"
          >
            Get Price Details
          </MButton>
          <MButton type="outlined" @click="handleClear"> Clear </MButton>
        </AgColumn>
      </AgRow>
    </form>
  </div>

  <OrganizationDetailPage
    :searchedEmail="emailInput"
    @fetchOrganizationDetails="handleFetchOrganizationDetails"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  MButton,
  MTextfield,
  MFinancialProfileCombobox,
} from "@aeroglobe/ag-core-ui";
import { GetFinancialProfileDto } from "@/modules/FinancialProfile/dtos/financialProfile.dto";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import OrganizationDetailPage from "@/modules/Organization/components/OrganizationDetails/OrganizationDetailPage.vue";

export default defineComponent({
  name: "AgentListView",
  components: {
    MFinancialProfileCombobox,
    MButton,
    MTextfield,
    OrganizationDetailPage,
  },
  data(): {
    financialProfileInput: string;
    emailInput: string;
    searchedEmail: string | null;
  } {
    return {
      financialProfileInput: "",
      emailInput: "",
      searchedEmail: null,
    };
  },
  methods: {
    handleGetPriceDetail() {
      this.searchedEmail = null;
      if (this.emailInput) {
        this.$store.dispatch("fetchOrganization", this.emailInput);
        this.searchedEmail = this.emailInput;
      } else {
        if (this.financialProfileInput) {
          this.$store.dispatch("fetchOrganization", this.financialProfileInput);
        }
      }
    },
    handleClear() {
      this.searchedEmail = null;
      this.financialProfileInput = "";
      this.emailInput = "";
      this.$store.commit("clearOrganization");
      this.$store.commit("setErrorOrganizationNotFound", false);
    },
    handleFetchOrganizationDetails() {
      this.$store.dispatch("fetchOrganization", this.financialProfileInput);
    },
    getPriceBtnDisabled(): boolean {
      return (
        (!this.emailInput && !this.financialProfileInput) ||
        this.$store.getters.isFetchingOrganization
      );
    },
  },
  computed: {
    financialProfileOptions() {
      const profiles = this.$store.getters
        .financialProfiles as IFinancialProfile[];
      return profiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.organization_public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
    fetchingPriceDetails() {
      return this.$store.getters.isFetchingOrganization;
    },
    isLinkingFinancialProfile(): boolean {
      return this.$store.getters.isLinkingFinancialProfile;
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
  },
  created() {
    this.$store.commit("clearOrganization");
  },
});
</script>

<style scoped lang="scss">
.heading {
  margin-bottom: 8px;
  & > h5 {
    margin-bottom: 8px;
  }
}
.search-bar-card {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 15px;
}
.get-price-detail-btn {
  margin-right: 15px;
}
</style>
