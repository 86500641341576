import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_combobox = _resolveComponent("m-combobox")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_m_textfield = _resolveComponent("m-textfield")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDialog = _resolveComponent("MDialog")!

  return (_openBlock(), _createBlock(_component_MDialog, {
    modelValue: _ctx.isMdialogOpen,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isMdialogOpen) = $event)),
    onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.closeModal())),
    "max-width": "900",
    title: _ctx.isEdit ? 'Edit Passenger' : 'Add Passenger'
  }, {
    content: _withCtx(() => [
      _createVNode(_component_ag_row, { class: "modal-first-row" }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_combobox, {
                inputValue: _ctx.passenger_type,
                "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passenger_type) = $event)),
                hasError: _ctx.errors?.passenger_type,
                errorMessage: _ctx.errors?.passenger_type,
                label: "Passenger Type",
                itemValue: "value",
                itemLabel: "label",
                options: _ctx.passenger_types
              }, null, 8, ["inputValue", "hasError", "errorMessage", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_combobox, {
                inputValue: _ctx.title,
                "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                hasError: _ctx.errors?.title,
                errorMessage: _ctx.errors?.title,
                label: "Passenger Type",
                itemValue: "value",
                itemLabel: "label",
                options: _ctx.renderTitleData
              }, null, 8, ["inputValue", "hasError", "errorMessage", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_textfield, {
                inputValue: _ctx.first_name,
                "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.first_name) = $event)),
                inputValueModifiers: { trim: true },
                label: "First Name and Middle Name (if any)",
                hasError: _ctx.errors?.first_name,
                errorMessage: _ctx.errors?.first_name
              }, null, 8, ["inputValue", "hasError", "errorMessage"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_textfield, {
                inputValue: _ctx.last_name,
                "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.last_name) = $event)),
                inputValueModifiers: { trim: true },
                label: "Last Name",
                hasError: _ctx.errors?.last_name,
                errorMessage: _ctx.errors?.last_name
              }, null, 8, ["inputValue", "hasError", "errorMessage"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_combobox, {
                inputValue: _ctx.nationality,
                "onUpdate:inputValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nationality) = $event)),
                hasError: _ctx.errors?.nationality,
                errorMessage: _ctx.errors?.nationality,
                label: "Nationality",
                itemValue: "value",
                itemLabel: "label",
                options: _ctx.nationalities
              }, null, 8, ["inputValue", "hasError", "errorMessage", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_textfield, {
                inputValue: _ctx.cnic,
                "onUpdate:inputValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cnic) = $event)),
                inputValueModifiers: { trim: true },
                label: "CNIC Number",
                hasError: _ctx.errors?.cnic,
                errorMessage: _ctx.errors?.cnic
              }, null, 8, ["inputValue", "hasError", "errorMessage"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_combobox, {
                inputValue: _ctx.gender,
                "onUpdate:inputValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.gender) = $event)),
                hasError: _ctx.errors?.gender,
                errorMessage: _ctx.errors?.gender,
                label: "Gender",
                itemValue: "value",
                itemLabel: "label",
                options: _ctx.genders
              }, null, 8, ["inputValue", "hasError", "errorMessage", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "6",
            cols: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_m_textfield, {
                inputValue: _ctx.passport_number,
                "onUpdate:inputValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.passport_number) = $event)),
                inputValueModifiers: { trim: true },
                label: "Passport Number",
                hasError: _ctx.errors?.passport_number,
                errorMessage: _ctx.errors?.passport_number
              }, null, 8, ["inputValue", "hasError", "errorMessage"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "12",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_calendar, {
                label: "Passport Expiry",
                "onUpdate:passport_expiry": _cache[8] || (_cache[8] = (value) => _ctx.passport_expiry = value),
                "calendar-name": "passport_expiry",
                date: _ctx.passport_expiry,
                "min-date": _ctx.minPassportExpiry,
                error: _ctx.errors?.passport_expiry
              }, null, 8, ["date", "min-date", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "12",
            md: "12",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_calendar, {
                label: "Date of Birth",
                "onUpdate:dob": _cache[9] || (_cache[9] = (value) => _ctx.dob = value),
                "calendar-name": "dob",
                "min-date": _ctx.disabledDates.to,
                "max-date": _ctx.disabledDates.from,
                date: _ctx.dob ? _ctx.dob : _ctx.disabledDates.to,
                "year-range": _ctx.disabledDates.yearsToShow,
                error: _ctx.errors?.dob
              }, null, 8, ["min-date", "max-date", "date", "year-range", "error"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_MButton, {
        disabled: _ctx.isLoading,
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onSubmit()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isEdit ? "Save" : "Add"), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}