import { CABIN_CLASS } from "../enums/cabin_class";
import { ROUTE_TYPE } from "../enums/route_type";
import { Airline } from "./Airline";
import {
  BaggageInfo,
  ExtendedPrice,
  PaxTypePriceBreakdown,
  Price,
} from "./BookingResponse";
import { JourneyLeg } from "./JourneyLeg";
import { Leg } from "./Leg";
import { Segment } from "./Segment";
import { Place } from "./Place";

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
}

export interface DealDiscount {
  discount_code: string;
  discount_offer: string;
  discount_type: string;
  discount_amount: number;
  discount_on_gross_fare: boolean;
  discount_currency: string | null;
}

export type TravelerCount = {
  adult_count: number;
  child_count: number;
  infant_count: number;
};

export type FlightsSearchBaseQuery = {
  route_type: ROUTE_TYPE;
  departure_date: Date;
  return_date: Date;
  cabin_class: CABIN_CLASS;
  traveler_count: TravelerCount;
  non_stop_flight: boolean;
  next_leg: number;
  current_leg: number;
  total_legs: number;
};

export type SelectedFlightLeg = FlightOption & {
  origin: Place;
  destination: Place;
  selectedFareOption: FareOption;
};

export type FlightPolicy = {
  policy_rules: Array<{
    rule: string;
    value: number;
  }>;
  notes: Array<string>;
};

export type FlightRouteStateParams = FlightsSearchBaseQuery & {
  legs: Leg[];
};

export type FlightRuleParams = {
  pre_booking_token: string;
};

export type FlightPreBookingTokenType = {
  pre_booking_tokens: string[];
  financial_profile_id: string;
};

export type FlightResponse = {
  is_international: boolean;
  route_type: ROUTE_TYPE;
  poll_id: string | null;
  keep_polling: boolean;
  journey_legs: Array<JourneyLeg>;
};

export type FlightDetailsType = {
  passengers: any[];
  flight_legs: FlightLeg[];
  ag_deal_discount?: ExtendedPrice | null;
  agent_total_amount?: ExtendedPrice | null;
  ag_price_difference?: ExtendedPrice | null;
  ag_total_amount: ExtendedPrice;
  ag_gross_fare: ExtendedPrice;
  price: Price & {
    currency: string;
    pax_type_price_breakdown: PaxTypePriceBreakdown;
  };
  flight_provider: string;
  flight_sub_provider: string;
  provider_booking_token: string;
  provider_confirmation_token: null | string;
  supplier_pnr: null | string;
  airline_pnr: null | string;
  route_type: ROUTE_TYPE;
  adult_count: number;
  child_count: number;
  infant_count: number;
  cabin_class: CABIN_CLASS;
  is_international: boolean;
  sector: string;
  pnr_expiry_status: string;
  raw_pnr_expiry: string[] | null;

  display_agent_pricing?: boolean;
  equivalent_agent_total_amount?: null | number;
  equivalent_ag_total_amount?: null | number;

  discount_data: DealDiscount;
};

export interface FlightDetails {
  passengers: any[];
  flight_legs: FlightLeg[];
  price: Price & {
    currency: string;
    pax_type_price_breakdown: PaxTypePriceBreakdown;
  };
  flight_provider: string;
  flight_sub_provider: string;
  provider_booking_token: string;
  provider_confirmation_token: null | string;
  supplier_pnr: null | string;
  airline_pnr: null | string;
  route_type: ROUTE_TYPE;
  adult_count: number;
  child_count: number;
  infant_count: number;
  cabin_class: CABIN_CLASS;
  is_international: boolean;
  sector: string;
  pnr_expiry_status: string;
  equivalent_ag_total_amount?: null | number;
}

export type FlightLeg = {
  segment: Segment[];
  origin: Place;
  destination: Place;
  departure_datetime: Date;
  arrival_datetime: Date;
  is_refundable: boolean;
  has_meal: boolean;
  baggage_info: BaggageInfo;
  leg_duration: number;
};

export interface AdditionalFlightDetails {
  hasMeal: boolean;
  baggageInfo: string;
}

export type MulticityLeg = {
  departure_date: Date;
  origin: string;
  destination: string;
};

export type MultiCityLegStateType = {
  origin: Place | null;
  destination: Place | null;
  departure_date: Date;
};

export type FetchFlightsPayload = {
  origin: Place;
  destination: Place;
  departure_date: string;
  return_date: string | null;
  non_stop_flight: boolean;
  traveler_count: TravelerCount;
  route_type: ROUTE_TYPE;
  cabin_class: CABIN_CLASS;
  legs?: MulticityLeg[];
  from_chip?: boolean;
  poll_id: string | null;
};

export type ReturnFlightPairStop = {
  label: string;
  value: number[];
};

export type FlightFilters = {
  airlines: FlightAirlneBaseFilter[];
  stops: FlightBaseFilter[];
  rtStops: ReturnFlightPairStop[];
  priceRange: Array<number>;
  departureTime: Array<any>;
};

export type SelectedFlightSearchQueryFilters = {
  airlines: Array<string>;
  stops: Array<string>;
  rtStops: Array<Array<number>>;
  priceRange: Array<number>;
  departureTime: Array<string>;
  maxPriceRangeSlider?: number;
};

export type FlightAirlneBaseFilter = {
  value: string;
  label: string;
  labelRt?: string;
};

export type FlightBaseFilter = {
  value: string;
  label: string;
};

export type returnFlightPairs = {
  ag_price_difference?: number;
  currency: string;
  source: string;
  destination: string;
  name: string;
  logo: string;
  price: number;
  base_fare: number;
  gross_fare: number;
  tax: number;
  ag_gross_fare?: number;
  pre_booking_token: string;
  next_booking: string;
  rbdFirst: string;
  rbdSecond: string;
  goingFlightNo: string;
  comingFlightNo: string;
  goingBagage: BaggageInfo;
  comingBagage: BaggageInfo;
  firstLegArrivalTime: string;
  firstLegDepartureTime: string;
  secondLegArrivalTime: string;
  secondLegDepartureTime: string;
  firstLegDuration: string;
  secondLegDuration: string;
  firstLegAirport: string;
  secondLegAirport: string;
  FO_1: FareOption;
  FO_2: FareOption;
  departure_date: Date;
  arrival_date: Date;
  code: string;
  provider: string;
};

export type FlightOption = {
  provider: string;
  name: string;
  duration: number;
  departure_time: string;
  arrival_time: string;
  departure_arrival_day_difference: number;
  airline: Airline;
  flight_numbers: string[];
  cheapest_fare_option_price: ExtendedPrice;
  fare_options: FareOption[];
};

export type FareOption = {
  pre_booking_token: string;
  rbd: string;
  baggage_info?: BaggageInfo;
  price: Price;
  is_refundable: boolean;
  cancellation_policy_items: string[] | null;
  modification_policy_items: string[] | null;
  baggage_policy_items: string[] | null;
  has_meal: boolean;
  segments: Segment[];
  next_fare_option_ids: string[];
  discount_data: DealDiscount;
};

export interface Options {
  flight_option?: FlightOption;
  fare_option: FareOption;
}

export interface ReturnFlightPair {
  departure: Options;
  arrival: Options;
}

export interface SABRE_AIRLINE {
  pcc: string | null;
  airline_code: string;
}

export interface GALILEO_AIRLINE {
  pcc: string | null;
  airline_code: string;
}

export interface FLIGHT_PROVIDER {
  name: string;
  value: string;
  sales_channel_name: string;
  is_ticket_issuance_enabled: boolean;
  is_active: boolean;
}

export interface ACTIVE_PROVIDERS_AND_AIRLINES_API_RESPONSE {
  active_sabre_airlines: SABRE_AIRLINE[];
  active_galileo_airlines: GALILEO_AIRLINE[];
  active_flight_providers: FLIGHT_PROVIDER[];
}

export interface IMultiCityAirports {
  origin: Place[];
  destination: Place[];
}
