import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac2dcc9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_2 = { class: "mchip-alignment td-full-width" }
const _hoisted_3 = { class: "mchip-alignment td-full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!
  const _component_PaymentDetailModal = _resolveComponent("PaymentDetailModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, { class: "whatsapp-management-heading" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AgIconInfoBar, {
            "test-id": "",
            title: "Payment Management",
            class: "section-title",
            mPrepandIcon: "m-payment-management"
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_MButton, {
              type: "filled",
              onClick: _ctx.routeToCreatePayment
            }, {
              default: _withCtx(() => [
                _createTextVNode("Create New Payment ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_MCard, null, {
      default: _withCtx(() => [
        (_ctx.$store.getters.isFetchingPayments)
          ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
          : (
        !_ctx.$store.getters.isFetchingPayments &&
        _ctx.$store.getters.payments.payments.length
      )
            ? (_openBlock(), _createBlock(_component_MDataTable, {
                key: 1,
                "is-api-paginated": true,
                "has-search": true,
                "total-item-count": _ctx.$store.getters.payments.totalRecords,
                headers: _ctx.headers,
                "item-per-page": _ctx.$store.getters.payments.pageSize,
                data: _ctx.$store.getters.payments.payments,
                onOnPageChange: _ctx.onPageChange
              }, {
                transactionId: _withCtx(({ item }) => [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "description td-full-width"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.transactionId), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                transactionType: _withCtx(({ item }) => [
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "description td-full-width"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.TRANSACTION_TYPES[item.transactionType]), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                paymentStatus: _withCtx(({ item }) => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_MChip, {
                      "border-less": true,
                      class: "pricing-chip",
                      variant: _ctx.paymentStatusClass(item.paymentStatus)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.PAYMENT_STATUSES[item.paymentStatus]), 1)
                      ]),
                      _: 2
                    }, 1032, ["variant"])
                  ])
                ]),
                receiptStatus: _withCtx(({ item }) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_MChip, {
                      "border-less": true,
                      class: "pricing-chip",
                      variant: _ctx.receiptStatusClass(item.receiptStatus)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.RECEIPT_STATUSES[item.receiptStatus]), 1)
                      ]),
                      _: 2
                    }, 1032, ["variant"])
                  ])
                ]),
                dateTime: _withCtx(({ item }) => [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "description td-full-width"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.dateTime, _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "description td-full-width"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.dateTime, _ctx.FORMAT_HH_SS_24_HOURS)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                amount: _withCtx(({ item }) => [
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "description td-full-width"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.amount, item.currency)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                action: _withCtx(({ item }) => [
                  _createVNode(_component_MButton, {
                    onClick: ($event: any) => (_ctx.showPaymentDetails(item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.transactionType === _ctx.PAYMENT_METHODS_TYPES.ONE_BILL
              ? "Update Status"
              : "View Details"), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["total-item-count", "headers", "item-per-page", "data", "onOnPageChange"]))
            : (_openBlock(), _createBlock(_component_ag_not_found, {
                key: 2,
                heading: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND
              }, null, 8, ["heading"]))
      ]),
      _: 1
    }),
    (_ctx.showDetailModal)
      ? (_openBlock(), _createBlock(_component_PaymentDetailModal, {
          key: 0,
          "show-modal": _ctx.showDetailModal,
          onOnCloseModal: _ctx.onCloseModal
        }, null, 8, ["show-modal", "onOnCloseModal"]))
      : _createCommentVNode("", true)
  ], 64))
}