<template>
  <MMenuWrapper @close:menu="showFareCalculatorMenu = false">
    <template #target>
      <MButton
        type="outlined"
        class="m-icon-btn"
        @click="showFareCalculatorMenu = !showFareCalculatorMenu"
      >
        <MIcon name="m-calculate" class="btn-icon" width="20" height="20" />
        <span>Fare Calculator</span>
      </MButton>
    </template>

    <template #menu>
      <MCard class="fc-menu m-index-level-two" v-show="showFareCalculatorMenu">
        <form class="fc-form">
          <MTextfield
            class="fc-amount-input"
            v-model:input-value="amount"
            placeholder="Enter Amount"
            type="number"
            dense
          />

          <div class="fc-amount-type">
            <div class="fc-title">Select Amount Type</div>
            <MRadioButton
              v-model="amountType"
              class="fc-radio-input"
              name="amount-type"
              :value="AmountType.FIXED"
            >
              Fix Amount
            </MRadioButton>
            <MRadioButton
              v-model="amountType"
              class="fc-radio-input"
              name="amount-type"
              :value="AmountType.PERCENTAGE"
            >
              Percentage Amount
            </MRadioButton>
          </div>

          <div class="fc-fare-type">
            <div class="fc-title">Select Fare Type</div>
            <MRadioButton
              v-model="fareType"
              class="fc-radio-input"
              name="fare-type"
              :value="FareType.BASE"
            >
              Base Fare
            </MRadioButton>
            <MRadioButton
              v-model="fareType"
              class="fc-radio-input"
              name="fare-type"
              :value="FareType.GROSS"
            >
              Gross Fare
            </MRadioButton>
          </div>

          <div class="fc-actions">
            <MButton type="outlined" dense @click="onClearFareHandler">
              Clear
            </MButton>
            <MButton dense @click="onApplyTemperFareHandler">Apply</MButton>
          </div>
        </form>
      </MCard>
    </template>
  </MMenuWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  MCard,
  MButton,
  MIcon,
  MMenuWrapper,
  MTextfield,
  MRadioButton,
} from "@aeroglobe/ag-core-ui";
import { AmountType, FareType, TemperFare } from "../types";

export default defineComponent({
  name: "SingleFlightCard",
  components: {
    MCard,
    MButton,
    MIcon,
    MMenuWrapper,
    MTextfield,
    MRadioButton,
  },
  emits: ["temper:fare", "clear:fare"],
  data() {
    return {
      AmountType,
      FareType,

      showFareCalculatorMenu: false,

      amount: "0",
      amountType: AmountType.FIXED as AmountType,
      fareType: FareType.GROSS,
    };
  },
  methods: {
    onApplyTemperFareHandler(): void {
      const temperFare: TemperFare = {
        amount: Number(this.amount),
        amountType: this.amountType,
        fareType: this.fareType,
      };

      this.$emit("temper:fare", temperFare);

      this.showFareCalculatorMenu = false;
    },
    onClearFareHandler(): void {
      this.amount = "0";
      this.$emit("clear:fare");

      this.showFareCalculatorMenu = false;
    },
  },
});
</script>

<style>
.fc-form .m-radio-button .rb-label {
  font-size: 14px !important;
}
</style>

<style scoped lang="css">
.fc-menu {
  width: 280px;
  --m-menu-distance: calc(100% + 8px);
  top: var(--m-menu-distance);
  right: 0;

  position: absolute;
  box-shadow: var(--m-menu-shadow-color);

  user-select: none;
  font-size: 14px !important;
}

.fc-form {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.fc-form .fc-title {
  font-weight: 500;
  margin-bottom: 8px;
}

.fc-form .fc-amount-input {
  margin-bottom: 0;
}

.fc-form .fc-radio-input:not(:last-child) {
  margin-bottom: 8px;
}

.fc-form .fc-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
</style>
