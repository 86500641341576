import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgSelectedFlightInfo = _resolveComponent("AgSelectedFlightInfo")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_FaciltyChip = _resolveComponent("FaciltyChip")!
  const _component_agdiv = _resolveComponent("agdiv")!
  const _component_AgPropertyReview = _resolveComponent("AgPropertyReview")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_PassengerDetailCard = _resolveComponent("PassengerDetailCard")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_BookingExpiryTimer = _resolveComponent("BookingExpiryTimer")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_PaymentPolicy = _resolveComponent("PaymentPolicy")!
  const _component_CancellationPolicy = _resolveComponent("CancellationPolicy")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_HotelIssuanceDialog = _resolveComponent("HotelIssuanceDialog")!
  const _component_CancelBookingConfirmationDialog = _resolveComponent("CancelBookingConfirmationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$store.state.hotelModule.isLoading)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : (_openBlock(), _createBlock(_component_ag_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              cols: "12",
              sm: "12",
              md: "12",
              lg: "8"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgSelectedFlightInfo, null, {
                  pnrInfo: _withCtx(() => [
                    _createVNode(_component_ag_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgRow, {
                          "test-id": "",
                          class: "v-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AgColumn, {
                              "test-id": "",
                              md: "8"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AgSelectedFlightInfo, null, {
                                  pnrInfo: _withCtx(() => [
                                    _createVNode(_component_AgDiv, { class: "booking_id_wrap" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Booking ID: "),
                                        _createElementVNode("span", null, _toDisplayString(_ctx.renderBookingId), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_AgColumn, {
                              "test-id": "",
                              md: "4",
                              class: "text-right"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AGButton, {
                                  "test-id": "",
                                  type: "button",
                                  variant: _ctx.renderStatusSeverity
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.renderBookingStatus), 1)
                                  ]),
                                  _: 1
                                }, 8, ["variant"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgPropertyReview, {
                  "test-id": "",
                  rating: _ctx.bookingDetails?.property?.rating,
                  "hotel-thumb": _ctx.formatUrl(_ctx.bookingDetails?.property.main_image_url || ''),
                  "hotel-name": _ctx.roomQoute(_ctx.bookingDetails?.property?.name),
                  "room-quote": _ctx.roomQoute(_ctx.bookingDetails?.room?.room_type),
                  "checkin-date": _ctx.renderDate(_ctx.bookingDetails?.checkin),
                  "checkout-date": _ctx.renderDate(_ctx.bookingDetails?.checkout),
                  "no-of-night": 
          _ctx.getNights(_ctx.bookingDetails?.checkin, _ctx.bookingDetails?.checkout)
        ,
                  address: _ctx.bookingDetails?.property.address1,
                  location: _ctx.renderLocation(_ctx.bookingDetails?.property),
                  meal_board_basis: _ctx.bookingDetails?.room.meal_board_basis
                }, {
                  hotelImages: _withCtx(() => [
                    _createVNode(_component_agdiv, { style: {"display":"flex","flex-wrap":"wrap","align-items":"center"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_agdiv, { style: {"display":"flex","flex-wrap":"wrap"} }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedFacilities, (item, index) => {
                              return (_openBlock(), _createBlock(_component_FaciltyChip, {
                                key: index,
                                name: item.value,
                                "icon-name": item.Icon
                              }, null, 8, ["name", "icon-name"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["rating", "hotel-thumb", "hotel-name", "room-quote", "checkin-date", "checkout-date", "no-of-night", "address", "location", "meal_board_basis"]),
                _createVNode(_component_ag_heading, {
                  variant: "h2",
                  title: "Passenger Details",
                  class: "margin_bottom_20"
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingDetails?.guests, (guest, index) => {
                  return (_openBlock(), _createBlock(_component_PassengerDetailCard, {
                    key: index,
                    guest: guest
                  }, null, 8, ["guest"]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              cols: "12",
              sm: "12",
              md: "12",
              lg: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MCard, { class: "booking-expiry-timer" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BookingExpiryTimer, {
                      status: _ctx.renderBookingStatus,
                      bookingExpiry: _ctx.getExpiry
                    }, null, 8, ["status", "bookingExpiry"])
                  ]),
                  _: 1
                }),
                (_ctx.bookingDetails?.ag_payment_date)
                  ? (_openBlock(), _createBlock(_component_PaymentPolicy, {
                      key: 0,
                      "ag-payment-date": _ctx.bookingDetails?.ag_payment_date
                    }, null, 8, ["ag-payment-date"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_CancellationPolicy, {
                  "cancellation-terms": _ctx.bookingDetails?.room?.cancellation_terms
                }, null, 8, ["cancellation-terms"]),
                _createVNode(_component_PriceSummary, {
                  "propert-name": _ctx.bookingDetails?.room?.room_type,
                  "total-currency": 
          _ctx.bookingDetails?.price.currency + ' ' + _ctx.bookingDetails?.price.value
        ,
                  "traveler-count": _ctx.bookingDetails?.guests?.length
                }, {
                  hoteltDetailsActionButtons: _withCtx(() => [
                    _withDirectives(_createVNode(_component_AGButton, {
                      onClick: _ctx.openIssuanceDialog,
                      class: "full-width margin_bottom_10",
                      "test-id": ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Proceed To Issuance ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]), [
                      [_vShow, _ctx.canProceedToIssuance]
                    ]),
                    _withDirectives(_createVNode(_component_AGButton, {
                      "is-loading": _ctx.isCancellingConfirmedBooking,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCancelBookingDialogOpen = true)),
                      class: "full-width margin_bottom_10",
                      "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'cancel-booking'),
                      variant: "danger"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Cancel Booking ")
                      ]),
                      _: 1
                    }, 8, ["is-loading", "test-id"]), [
                      [_vShow, _ctx.isStatusIssuedButNotPaid]
                    ]),
                    _withDirectives(_createVNode(_component_AGButton, {
                      "is-loading": _ctx.isPayingForBooking,
                      onClick: _ctx.onPayNow,
                      class: "full-width margin_bottom_10",
                      "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'pay-now-booking')
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Pay Now ")
                      ]),
                      _: 1
                    }, 8, ["is-loading", "onClick", "test-id"]), [
                      [_vShow, _ctx.isStatusIssuedButNotPaid]
                    ])
                  ]),
                  _: 1
                }, 8, ["propert-name", "total-currency", "traveler-count"]),
                (_ctx.canDownloadVoucher)
                  ? (_openBlock(), _createBlock(_component_AgCard, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_heading, {
                          variant: "h2",
                          title: "View Voucher",
                          class: "margin_bottom_20"
                        }),
                        _createVNode(_component_AGButton, {
                          "is-loading": _ctx.isDownloadingVoucher,
                          onClick: _ctx.onDownloadVoucherHandler,
                          class: "full-width",
                          "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'download-voucher')
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Download ")
                          ]),
                          _: 1
                        }, 8, ["is-loading", "onClick", "test-id"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })),
    _createVNode(_component_HotelIssuanceDialog, {
      "is-open": _ctx.isIssuanceDialogOpen,
      "handle-close-dialog": _ctx.handleCloseDialog
    }, null, 8, ["is-open", "handle-close-dialog"]),
    _createVNode(_component_CancelBookingConfirmationDialog, {
      "is-open": _ctx.isCancelBookingDialogOpen,
      onHandleAccept: _ctx.onCancelBooking,
      onHandleReject: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCancelBookingDialogOpen = false))
    }, null, 8, ["is-open", "onHandleAccept"])
  ], 64))
}