<template>
  <AgTabs test-id="test">
    <template #TabHeading>
      <v-tab value="one">
        <MIcon name="m-deals" />
        <span>Deals</span>
      </v-tab>
      <v-tab value="two">
        <MIcon name="m-promos" />
        <span>Promos</span></v-tab
      >
    </template>
    <template #TabDetail>
      <v-window-item
        value="two"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <div v-if="isFetchingPromosAndDeals">
          <ShimmerCard card-height="200px" />
          <ShimmerCard card-height="200px" />
        </div>
        <AgNotFound
          v-else-if="dontHavePromos"
          test-id=""
          heading="No Promos Found"
          class="not-found"
        />
        <MCard class="promos-container" v-else>
          <div
            v-for="promo in applicablePromos"
            :key="promo.discount_code"
            class="promos-card"
          >
            <Promos
              :active="promo.is_active"
              :amount="
                formatPromoAmount(
                  promo.discount_amount,
                  promo.discount_amount_type,
                  promo.currency
                )
              "
              :start="promo.start_date"
              :end="promo.end_date"
              :promo_name="promo.name"
              :applicable_on="
                formatPromoApplicableOn(
                  promo.apply_on_airline,
                  promo.airline_data
                )
              "
              :promo_code="promo.discount_code"
              :gross-apply="promo.apply_on_gross"
              :discount_mode="promo.discount_mode"
              :organization_created_in_days="promo.organization_created_in_days"
              :last_issuance_before_days="promo.last_issuance_before_days"
              :max_usage="promo.max_usage"
              :for_portal_only="promo.for_portal_only"
              :route="promo.route_map"
              :flightSector="renderFlightSector(promo.flight_sector)"
              :originAirports="renderAirports(promo.origin_airports)"
              :destinationAirports="renderAirports(promo.destination_airports)"
            />
          </div>
        </MCard>
      </v-window-item>
      <v-window-item
        value="one"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <div v-if="isFetchingPromosAndDeals">
          <ShimmerCard card-height="200px" />
          <ShimmerCard card-height="200px" />
        </div>
        <AgNotFound
          v-else-if="dontHaveDeals"
          test-id=""
          heading="No Deals Found"
        />
        <MCard class="deals-container" v-else>
          <div
            v-for="deal in applicableDeals"
            :key="deal.discount_code"
            class="deals-card"
          >
            <Deals
              :is-active="deal.is_active"
              :amount="
                formatPromoAmount(
                  deal.discount_amount,
                  deal.discount_amount_type,
                  deal.currency
                )
              "
              :start="deal.start_date"
              :end="deal.end_date"
              :deal_name="deal.name"
              :applicable_on="
                formatPromoApplicableOn(
                  deal.apply_on_airline,
                  deal.airline_data
                )
              "
              :discount_code="deal.discount_code"
              :discount_mode="deal.discount_mode"
              :is-featured="deal.is_featured"
              :gross-apply="deal.apply_on_gross"
              :portal-only="deal.for_portal_only"
              :route="deal.route_map"
              :flightSector="renderFlightSector(deal.flight_sector)"
              :type="deal.discount_amount_type"
              :originAirports="renderAirports(deal.origin_airports)"
              :destinationAirports="renderAirports(deal.destination_airports)"
            />
          </div>
        </MCard>
      </v-window-item>
    </template>
  </AgTabs>
</template>

<script lang="ts">
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { defineComponent } from "vue";
import Deals from "@/modules/Deals/components/Deals.vue";
import Promos from "@/modules/Deals/components/Promos.vue";
import { AirlineData, Deal, Promo } from "../constants";
import { MCard } from "@aeroglobe/ag-core-ui";
import ShimmerCard from "@/components/ShimmerCard.vue";

export default defineComponent({
  name: "promosAndDeals",
  components: {
    Deals,
    Promos,
    MCard,
    ShimmerCard,
  },
  async created() {
    await this.$store.dispatch(
      "getOrganizationPromosAndDeals",
      this.getOrganizationId
    );
  },
  computed: {
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    getOrganizationId(): string {
      const organizationId = this.organization
        ? this.organization.organization_id
        : "";
      return organizationId;
    },
    isFetchingPromosAndDeals(): boolean {
      return this.$store.getters.isFetchingPromosAndDeals;
    },
    applicableDeals(): Deal[] | null {
      return this.$store.getters.deals;
    },
    applicablePromos(): Promo[] | null {
      return this.$store.getters.promos;
    },
    dontHavePromos(): boolean {
      const promos = this.$store.getters.promos;
      return promos?.length === 0 || null ? true : false;
    },
    dontHaveDeals(): boolean {
      const deals = this.$store.getters.deals;
      return deals?.length === 0 || null ? true : false;
    },
  },
  methods: {
    formatPromoAmount(amount: number, type: string, currency: string): string {
      return type == "PERCENTAGE" ? amount + "%" : currency + " " + amount;
    },
    formatPromoApplicableOn(
      applicableOn: string,
      airlines: AirlineData[] | null
    ) {
      if (applicableOn !== "SELECTED") {
        return applicableOn + " " + "Airlines";
      } else {
        if (airlines && airlines.length > 0) {
          return airlines
            .map(
              (airline) =>
                airline.airline_name + " (" + airline.airline_code + ")"
            )
            .join(", ");
        }
        return "";
      }
    },
    renderFlightSector(flightSector: string[] | null): string {
      return flightSector?.map((sector) => sector).join(", ") || "";
    },
    renderAirports(Airports: string[] | null): string {
      return Airports?.map((airport) => airport).join(", ") || "";
    },
  },
});
</script>
<style lang="css" scoped>
.promos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 16px;
}

.promos-card {
  flex: 1 1 calc(50% - 10px);
  max-width: calc(50% - 10px);
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .promos-card {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

.deals-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 16px;
}

.deals-card {
  flex: 1 1 calc(33% - 10px);
  max-width: calc(33% - 10px);
  box-sizing: border-box;
}

@media (max-width: 1420px) {
  .deals-card {
    flex: 1 1 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 1130px) {
  .deals-card {
    flex: 1 1 100%;
    max-width: calc(100% - 15px);
  }
}

@media (max-width: 830px) {
  .deals-card {
    flex: 1 1 100%;
    min-width: 100%;
  }
}
</style>
