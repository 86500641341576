<template>
  <div class="flight-route" :view-type="viewType">
    <div class="fr-column">
      <div class="fr-time">{{ details.originCode }}</div>
      <div class="fr-code">{{ details.originTime }}</div>
    </div>

    <div class="fr-column fr-route-details">
      <div class="fr-duration">{{ details.duration }}</div>
      <div class="fr-graphics">
        <MDivider class="fr-border" />
        <MIcon class="fr-icon" name="m-flight" />
        <MDivider class="fr-border" />
      </div>
      <div class="fr-code">
        <span>
          <b>{{ stopsLabel }}</b>
        </span>
        {{ " " }}
        <span v-if="stopsWithLayoffs.length > 0">
          ({{ stopsWithLayoffs.join(", ") }})
        </span>
      </div>
    </div>

    <div class="fr-column">
      <div class="fr-time">{{ details.destinationCode }}</div>
      <div class="fr-code">{{ details.destinationTime }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MIcon, MDivider } from "@aeroglobe/ag-core-ui";

import { ListViewType, RouteDetails } from "../types";

export default defineComponent({
  name: "FlightRoute",
  props: {
    viewType: {
      type: String as PropType<ListViewType>,
      default: ListViewType.SINGLE,
    },
    details: {
      type: Object as PropType<RouteDetails>,
      required: true,
    },
  },
  components: {
    MIcon,
    MDivider,
  },
  computed: {
    stopsWithLayoffs(): string[] {
      return this.details.stops.filter((_, index) => this.haveLayOff(index));
    },
    stopsLabel(): string {
      const stopsLength = this.stopsWithLayoffs.length;

      if (stopsLength > 0) {
        return `${stopsLength} Stop${stopsLength > 1 ? "s" : ""}`;
      } else {
        return "Non Stop";
      }
    },
  },
  methods: {
    haveLayOff(index: number): boolean {
      return index + 1 < this.details.stops.length;
    },
  },
});
</script>

<style scoped lang="css">
.flight-route {
  color: var(--m-secondary-color);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.flight-route .fr-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flight-route .fr-route-details {
  width: 100%;
}
.flight-route[view-type="SINGLE"] .fr-route-details {
  width: 250px;
}
.flight-route[view-type="PAIR"] .fr-route-details {
  width: 100px;
}

.flight-route .fr-column .fr-time {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}
.flight-route .fr-column .fr-code {
  font-size: 12px;
  color: var(--m-label-color);
}
.flight-route .fr-column .fr-duration {
  font-size: 12px;
  font-weight: 500;
}

.flight-route .fr-column .fr-graphics {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 4px;

  color: var(--m-primary-color);

  margin: 4px 0;
}
.flight-route .fr-column .fr-graphics .fr-border {
  width: 100%;
  min-width: 30px;

  border-color: color-mix(
    in srgb,
    var(--m-primary-color) 50%,
    var(--m-light-color) 100%
  );

  border-width: 1px;
  border-radius: 2px;
}
.flight-route .fr-column .fr-graphics .fr-icon {
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: rotate(90deg);
}
</style>
