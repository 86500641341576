<template>
  <MDialog
    v-model="isMdialogOpen"
    title="Update Providers"
    @close="closeDialog"
    class="update-providers m-scrollbar"
  >
    <template #content>
      <div class="update-providers-content">
        <MCombobox
          v-model:inputValue="localPiaProvider"
          label="PIA Provider"
          itemValue="value"
          itemLabel="label"
          :options="piaProvidersOptions"
          :hasError="!!piaProviderError"
          errorMessage="Required"
          name="piaProvider"
        />
        <MCombobox
          v-model:inputValue="localAirblueProvider"
          label="Airblue Provider"
          itemValue="value"
          itemLabel="label"
          :options="airblueProvidersOptions"
          :hasError="!!airblueProviderError"
          errorMessage="Required"
          name="airblueProvider"
        />
      </div>
    </template>
    <template #actions>
      <MButton :disabled="isLoading" @click="onUpdate()">Update</MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { MCombobox, MButton, MDialog } from "@aeroglobe/ag-core-ui";
import { UpdateFinancialProfileProviders } from "../models/UpdateFinancialProfileProviders.request";

export default defineComponent({
  name: "UpdateFinancialProfileProviders",
  components: { MCombobox, MButton, MDialog },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    financialProfileId: {
      type: String,
      required: true,
    },
    piaProvider: {
      type: String,
    },
    airblueProvider: {
      type: String,
    },
  },
  data() {
    return {
      localPiaProvider: "",
      localAirblueProvider: "",
      piaProviderError: false,
      airblueProviderError: false,
      isMdialogOpen: this.isOpen,
    };
  },
  computed: {
    piaProvidersOptions(): ComboboxOptions[] {
      const providers = this.$store.getters.PIAProviders || [];
      return providers.map((x: string) => ({ label: x, value: x }));
    },
    airblueProvidersOptions(): ComboboxOptions[] {
      const providers = this.$store.getters.airblueProviders || [];
      return providers.map((x: string) => ({ label: x, value: x }));
    },
    isLoading(): boolean {
      return this.$store.getters.isUpdateFinancialProfileProvidersLoading;
    },
  },
  methods: {
    onUpdate() {
      this.piaProviderError = !this.localPiaProvider;
      this.airblueProviderError = !this.localAirblueProvider;

      if (this.piaProviderError || this.airblueProviderError) {
        return;
      }
      const payload: UpdateFinancialProfileProviders = {
        financial_profile_public_id: this.financialProfileId,
        pia_provider: this.localPiaProvider,
        airblue_provider: this.localAirblueProvider,
      };

      this.$store.dispatch("updateFinancialProfileProviders", {
        payload,
        successHandler: () => this.$emit("onSuccess"),
      });
    },
    closeDialog() {
      this.$emit("handleClose");
    },
  },
  watch: {
    piaProvider: {
      handler(provider) {
        this.localPiaProvider = provider;
      },
      immediate: true,
    },
    airblueProvider: {
      handler(provider) {
        this.localAirblueProvider = provider;
      },
      immediate: true,
    },
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("closeModal");
      }
    },
  },
});
</script>

<style lang="css" scoped>
.update-fp-financial-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.update-providers-content {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<style lang="css">
@media screen and (min-height: 960px) and (min-width: 960px) {
  .update-providers,
  .update-providers .m-dialog-content {
    overflow: visible;
  }
}
</style>
