<template>
  <MCard :class="['sf-flight-card', { active: isSideSheetOpen }]">
    <div class="sf-details">
      <img
        class="sf-flight-image"
        :src="flight.airline.logo"
        alt="flight-image"
        width="24"
        height="24"
      />

      <div class="sf-column sf-header">
        <div class="sf-airline-title">
          {{ flight.airline.name }}
        </div>
        <div class="sf-airline-number">
          {{ flight.flight_numbers[0] }}
        </div>
        <div class="sf-date">
          {{ getDepartureTime(flight.departure_time) }}
        </div>
      </div>

      <div class="flight-details">
        <FlightRoute :details="routeDetails" />
      </div>

      <div class="sf-actions">
        <div class="sf-info-action">
          <div class="sf-provider">{{ flight.provider }}</div>
          <MDivider type="vertical" />
          <MIcon
            class="sf-icon sf-qoutations"
            title="Add to qoutations"
            name="m-post-add"
            @click="onAddToQuoteHandler"
          />
        </div>

        <div class="sf-details-action" @click="onViewDetailsHandler">
          <div class="sfd-title">View Details</div>
          <MIcon class="sf-icon primary" name="m-sidebar" />
        </div>
      </div>
    </div>

    <SingleFareOptions
      :fare-options="flight.fare_options"
      :temper-fare="temperFare"
      @select:fare="onSelectFareHandler"
    />

    <MSideSheet v-model="isSideSheetOpen" title="Flight Details">
      <div class="sfs-flight-details">
        <div class="sfs-flight-info">
          <div class="sfs-flight-info-title">
            ({{ routeDetails.originCode }} - {{ routeDetails.destinationCode }})
            - {{ routeDetails.originTime }} -
            {{ routeDetails.destinationTime }} ({{ routeDetails.duration }})
          </div>

          <div class="sfs-flight-info-subtitle">
            <img
              class="sfs-flight-info-row-image"
              :src="flight.airline.logo"
              alt="flight-image"
              width="18"
              height="18"
            />
            <div>
              {{ flight.airline.name }} • {{ flight.flight_numbers.join(", ") }}
            </div>
          </div>
        </div>

        <MSegmentedButton
          class="sfs-segments"
          :segments="flightDetailsSegments"
        />

        <FlightSegments
          v-show="flightDetailsSegments[0].checked"
          :segments="flight.fare_options[0].segments"
        />

        <div class="sfs-cancellation" v-show="flightDetailsSegments[1].checked">
          <div class="sfs-cancellation-chips">
            <template
              v-for="(option, index) in flight.fare_options"
              :key="index"
            >
              <MChip
                :variant="`${option.is_refundable ? 'success' : 'error'}`"
                border-less
              >
                {{ getRbdNameHandler(option.rbd) }} -
                {{ option.is_refundable ? "Refundable" : "Non-Refundable" }}
              </MChip>
            </template>
          </div>

          <template
            v-if="
              fareRules && fareRules.cancellation_policy.policy_rules.length > 0
            "
          >
            <div class="sfs-rules">
              <div
                class="sfs-rule"
                v-for="(rule, index) in fareRules.cancellation_policy
                  .policy_rules"
                :key="index"
              >
                <div class="sfs-rule-title">
                  {{ rule.rule }}
                </div>
                <div class="sfs-rule-subtitle">
                  {{ rule.value }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="sfs-rules">
              <div class="sfs-rule">
                <div class="sfs-rule-title">Cancellation Rules</div>
                <div class="sfs-rule-subtitle">As per airline policy</div>
              </div>
            </div>
          </template>
        </div>

        <div class="sfs-modification" v-show="flightDetailsSegments[2].checked">
          <template
            v-if="
              fareRules && fareRules.modification_policy.policy_rules.length > 0
            "
          >
            <div class="sfs-rules">
              <div
                class="sfs-rule"
                v-for="(rule, index) in fareRules.modification_policy
                  .policy_rules"
                :key="index"
              >
                <div class="sfs-rule-title">
                  {{ rule.rule }}
                </div>
                <div class="sfs-rule-subtitle">
                  {{ rule.value }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="sfs-rules">
              <div class="sfs-rule">
                <div class="sfs-rule-title">Modification Rules</div>
                <div class="sfs-rule-subtitle">As per airline policy</div>
              </div>
            </div>
          </template>
        </div>

        <div class="sfs-baggage" v-show="flightDetailsSegments[3].checked">
          <template
            v-if="fareRules && fareRules.baggage_policy.policy_rules.length > 0"
          >
            <div class="sfs-rules">
              <div
                class="sfs-rule"
                v-for="(rule, index) in fareRules.baggage_policy.policy_rules"
                :key="index"
              >
                <div class="sfs-rule-title">
                  {{ rule.rule }}
                </div>
                <div class="sfs-rule-subtitle">
                  {{ rule.value }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="sfs-rules">
              <div class="sfs-rule">
                <div class="sfs-rule-title">Baggage Rules</div>
                <div class="sfs-rule-subtitle">As per airline policy</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </MSideSheet>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  MChip,
  MCard,
  MIcon,
  MDivider,
  MSideSheet,
  MSegmentedButton,
} from "@aeroglobe/ag-core-ui";

import FlightRoute from "./FlightRoute.vue";
import SingleFareOptions from "./SingleFareOptions.vue";
import FlightSegments from "./FlightSegments.vue";

import {
  ListViewType,
  RouteDetails,
  FlightRulesResponse,
  TemperFare,
} from "../types";
import { FareOption, FlightOption } from "@/ag-flight-components/types";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import {
  MDataTableHeader,
  MSegment,
} from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "SingleFlightCard",
  components: {
    MChip,
    MCard,
    MIcon,
    MDivider,
    MSideSheet,
    MSegmentedButton,

    FlightRoute,
    SingleFareOptions,
    FlightSegments,
  },
  props: {
    flight: {
      type: Object as PropType<FlightOption>,
      required: true,
    },
    flightsFareRules: {
      type: Object as PropType<Map<string, FlightRulesResponse>>,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
  },
  emits: ["select:fare", "fetch:rules"],
  computed: {
    routeDetails(): RouteDetails {
      const segments = this.flight.fare_options[0].segments;
      const duration = this.getDuration(this.flight.duration);

      const originSegment = segments[0];
      const destinationSegment = segments[segments.length - 1];

      return {
        originCode: originSegment.origin.iata_code,
        originTime: this.getFormattedTime(
          `${originSegment.departure_datetime}`
        ),

        destinationCode: destinationSegment.destination.iata_code,
        destinationTime: this.getFormattedTime(
          `${destinationSegment.arrival_datetime}`
        ),

        duration,

        stops: segments.map((segment) => segment.destination.iata_code),
      };
    },
    fareRules(): FlightRulesResponse | undefined {
      const preBookingToken = this.flight.fare_options[0].pre_booking_token;

      return this.flightsFareRules?.get(preBookingToken);
    },
  },
  data() {
    return {
      ListViewType,
      isSideSheetOpen: false,
      flightDetailsSegments: [
        {
          label: "Flight Info",
          checked: true,
        },
        {
          label: "Cancellation",
          checked: false,
        },
        {
          label: "Date Change",
          checked: false,
        },
        {
          label: "Baggage",
          checked: false,
        },
      ] as MSegment[],

      tableHeaders: [
        {
          title: "Name",
          value: "rule",
          key: "rule",
          sortable: false,
          contentAlign: "left",
        },
        {
          title: "Fee",
          value: "value",
          key: "value",
          sortable: false,
          contentAlign: "left",
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    getDepartureTime(departureTime: string): string {
      return TimeUtility.parsedFlightTimeorDate(
        departureTime,
        "EE, MMM dd, yyyy"
      );
    },
    getFormattedTime(time: string): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    getDuration(duration: number): string {
      return TimeUtility.getDurationInTextByMinutes(duration);
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onSelectFareHandler(fareOption: FareOption): void {
      this.$emit("select:fare", fareOption);
    },
    onViewDetailsHandler(): void {
      this.isSideSheetOpen = !this.isSideSheetOpen;

      if (this.isSideSheetOpen && !this.fareRules) {
        this.onFetchFareRulesHandler();
      }
    },
    async onFetchFareRulesHandler(): Promise<void> {
      this.$emit("fetch:rules", this.flight.fare_options[0].pre_booking_token);
    },
    onAddToQuoteHandler(): void {
      UTILS.addFlightToQoute(this.flight);
    },
  },
});
</script>

<style lang="css">
.sf-flight-card {
  padding: 0;
}
</style>

<style scoped lang="css">
.sf-flight-card {
  color: var(--m-secondary-color);
  padding: 0 !important;
}
.sf-flight-card:hover {
  box-shadow: 0 4px 10px var(--m-divider-color);
}
.sf-flight-card.active {
  box-shadow: -10px 4px 20px var(--m-divider-color);
}

.sf-details {
  display: flex;
  align-items: center;
  gap: 12px;

  border-radius: 8px 8px 0 0;
  border: 1px solid var(--m-flight-card-border-color);
  border-bottom: none;

  padding: 16px;
}

.sf-icon {
  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.sf-icon.primary {
  color: var(--m-primary-color);
}

.sf-details .sf-header {
  width: 200px;
  max-width: 200px;
}
.sf-details .sf-flight-image {
  width: 24px;
  height: 24px;

  object-fit: cover;
}
.sf-details .sf-airline-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.sf-details .sf-airline-number,
.sf-details .sf-date {
  font-size: 12px;
  line-height: 22px;

  color: var(--m-label-color);
}

.sf-details .flight-details:nth-child(even) {
  padding-left: 0;
}

.sf-details .sf-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  flex: 1;
}

.sf-details .sf-actions .sf-info-action {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sf-details .sf-actions .sf-info-action .m-divider {
  height: 20px;
}
.sf-details .sf-actions .sf-info-action .sf-provider {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 0 12px;

  font-size: 12px;
  font-weight: 500;

  color: var(--m-primary-color);
  background-color: color-mix(
    in srgb,
    var(--m-primary-color) 10%,
    var(--m-light-color) 100%
  );
  border-radius: 4px;
}
.sf-details .sf-actions .sf-info-action .sf-qoutations {
  cursor: pointer;

  transition: color 0.2s ease-in-out;
}
.sf-details .sf-actions .sf-info-action .sf-qoutations:hover {
  color: var(--m-primary-color);
}

.sf-details .sf-actions .sf-details-action {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 500;
  color: var(--m-primary-color);

  cursor: pointer;

  transition: opacity 0.2s ease-in-out;
}

.sf-details .sf-actions .sf-details-action:hover {
  opacity: 0.75;
}

.sfs-flight-details {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--m-secondary-color);

  padding: 8px;
}
.sfs-flight-details .sfs-flight-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sfs-flight-details .sfs-flight-info .sfs-flight-info-title {
  font-size: 16px;
  font-weight: 600;
}
.sfs-flight-details .sfs-flight-info .sfs-flight-info-subtitle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.sfs-flight-details .sfs-segments {
  margin: 20px auto 0;
}

.sfs-cancellation .sfs-cancellation-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  margin-top: 32px;
}

.sfs-rules {
  display: flex;
  flex-direction: column;

  gap: 4px;
  margin: 32px 0;
}

.sfs-rules .sfs-rule {
  font-size: 14px;

  border: 1px solid var(--m-divider-color);
  border-radius: 2px;
}
.sfs-rules .sfs-rule:only-child {
  border-radius: 8px;
}
.sfs-rules .sfs-rule:first-child:not(:last-child) {
  border-radius: 8px 8px 2px 2px;
}
.sfs-rules .sfs-rule:last-child:not(:first-child) {
  border-radius: 2px 2px 8px 8px;
}

.sfs-rules .sfs-rule .sfs-rule-title {
  font-weight: 500;
  border-bottom: 1px solid var(--m-divider-color);
}
.sfs-rules .sfs-rule .sfs-rule-subtitle {
  color: var(--m-label-color);
}
.sfs-rules .sfs-rule .sfs-rule-title,
.sfs-rules .sfs-rule .sfs-rule-subtitle {
  min-height: 32px;
  padding: 12px;

  display: flex;
  align-items: center;
}
</style>
