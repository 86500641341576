import {
  FareOption,
  FlightOption,
  FlightResponse,
  JourneyLeg,
  ReturnFlightPair,
} from "@/ag-flight-components/types";
import { SearchQuery } from "./query";
import FlightService from "../services/flight.service";
import { PollingService } from "./polling";
import {
  BookingInitiatedCallback,
  DateOption,
  Filter,
  FilterType,
  FlightRulesResponse,
  QueryData,
} from "../types";
import { FlightFilters } from "./filters";
import { ROUTE_TYPE } from "@/ag-portal-common/enums/ROUTE_TYPE";
import { getGrossFare } from "@/ag-flight-components/utils";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { addDays, isPast, isSameDay } from "date-fns";

export class FlightSearchResult {
  private journeyLegs: JourneyLeg[] = [];

  public flights: FlightOption[] = [];
  public pairs: ReturnFlightPair[] = [];

  public minPrice = 0;
  public maxPrice = 10;
  public currentLegIndex = 0;

  public selectedFares: FareOption[] = [];
  public searchQuery: SearchQuery;
  public isLoading = true;
  public isBooking = false;
  public isPairView = false;

  public flightsFareRules: Map<string, FlightRulesResponse> = new Map<
    string,
    FlightRulesResponse
  >();

  private pollingService: PollingService<FlightResponse, SearchQuery>;
  private filters: FlightFilters;

  private flightsService: FlightService;

  constructor(queryData: QueryData, filters: FlightFilters) {
    this.filters = filters;

    this.flightsService = new FlightService();

    this.searchQuery = new SearchQuery(queryData);

    this.pollingService = new PollingService<FlightResponse, SearchQuery>(
      this.searchQuery,
      (context) => this.flightsService.flights(context.query)
    );
  }

  get availableDates(): DateOption[] {
    const dates: DateOption[] = [];
    const departureDateStr = this.searchQuery.query.departure_date ?? "";
    const departureDate = departureDateStr ? new Date(departureDateStr) : null;

    const generateDates = (
      startDate: Date | null,
      endDate: Date | null,
      numDays: number,
      isIncremental = true
    ) => {
      if (!startDate) return;

      for (let i = 0; i < numDays; i++) {
        const dateOffset = isIncremental ? i : -i;
        const newDepartureDate = addDays(startDate, dateOffset).toISOString();
        const newArrivalDate = endDate
          ? addDays(endDate, dateOffset).toISOString()
          : undefined;

        // Check if the date pair already exists in the dates array
        const isDuplicate = dates.some(
          (dateOption) =>
            dateOption.departureDate === newDepartureDate &&
            dateOption.arrivalDate === newArrivalDate
        );

        if (!isDuplicate) {
          const dateOption = {
            departureDate: newDepartureDate,
            arrivalDate: newArrivalDate,
            active: isSameDay(
              addDays(startDate, dateOffset),
              new Date(departureDateStr)
            ),
          };
          dates.push(dateOption);
        }
      }
    };

    if (departureDate) {
      if (this.isReturnFlight) {
        const returnDateStr = this.searchQuery.query.return_date ?? "";
        const returnDate = returnDateStr ? new Date(returnDateStr) : null;

        if (returnDate && isSameDay(departureDate, returnDate)) {
          generateDates(departureDate, returnDate, 7);
        } else {
          generateDates(departureDate, returnDate, 4, false);
          generateDates(departureDate, returnDate, 4);
        }
      } else if (this.isOneWayFlight) {
        if (isSameDay(departureDate, new Date()) || isPast(departureDate)) {
          generateDates(new Date(), null, 7);
        } else {
          generateDates(departureDate, null, 4, false);
          generateDates(departureDate, null, 4);
        }
      }
    }

    dates.sort(
      (a, b) =>
        new Date(a.departureDate).getTime() -
        new Date(b.departureDate).getTime()
    );

    return dates;
  }
  get isNextLegAvailable(): boolean {
    const legsLength = this.journeyLegs.length;

    return this.currentLegIndex + 1 < legsLength;
  }
  get isOneWayFlight(): boolean {
    return this.searchQuery.query.route_type === ROUTE_TYPE.ONEWAY;
  }
  get isReturnFlight(): boolean {
    return this.searchQuery.query.route_type === ROUTE_TYPE.RETURN;
  }
  get isMultiCityFlight(): boolean {
    return this.searchQuery.query.route_type === ROUTE_TYPE.MULTI_CITY;
  }
  get filteredFlights(): FlightOption[] {
    if (this.filters.activeFilters.length === 0) {
      return this.flights;
    }

    const activeAirlines = this.filters.activeFilters
      .filter((filter) => filter.type === FilterType.AIRLINES)
      .map((airline) => airline.label);

    const activeStops = this.filters.activeFilters
      .filter((filter) => filter.type === FilterType.STOPS)
      .map((stop) => stop.count);

    const activeTimes = this.filters.activeFilters
      .filter((filter) => filter.type === FilterType.TIMES)
      .flatMap((time) => {
        const [start, end] = time.label.split(" - ").map(
          (d: string) =>
            d
              .split(":")
              .map(Number)
              .sort((a: number, b: number) => b - a)[0]
        );
        return Array.from({ length: end - start + 1 }, (_, i) =>
          (start + i).toString().padStart(2, "0")
        );
      });

    const activePrice = this.filters.activeFilters.find(
      (filter) => filter.type === FilterType.PRICE
    );

    const flightsCopy = [...this.flights];

    return flightsCopy.filter((flight) => {
      const isAirlineMatch =
        activeAirlines.length === 0 ||
        activeAirlines.includes(flight.airline.name);

      const stopsLength = flight.fare_options[0].segments.slice(0).length - 1;
      const isStopsMatch =
        activeStops.length === 0 ||
        activeStops.includes(stopsLength) ||
        stopsLength > 2;

      const flightDepartureHour = TimeUtility.parsedFlightTimeorDate(
        flight.departure_time,
        "HH"
      );

      const isTimeMatch =
        activeTimes.length === 0 || activeTimes.includes(flightDepartureHour);

      const isPriceMatch =
        !activePrice ||
        flight.fare_options.some(
          (option) => getGrossFare(option) <= activePrice.count
        );

      return isAirlineMatch && isStopsMatch && isTimeMatch && isPriceMatch;
    });
  }
  get filteredPairs(): ReturnFlightPair[] {
    if (this.filters.activeFilters.length === 0) {
      return this.pairs;
    }

    const activeAirlines = this.filters.activeFilters
      .filter((filter) => filter.type === FilterType.AIRLINES)
      .map((airline) => airline.label);

    const activeStops = this.filters.activeFilters
      .filter((filter) => filter.type === FilterType.STOPS)
      .map((stop) => stop.count);

    const activeTimes = this.filters.activeFilters
      .filter((filter) => filter.type === FilterType.TIMES)
      .flatMap((time) => {
        const [start, end] = time.label.split(" - ").map(
          (d: string) =>
            d
              .split(":")
              .map(Number)
              .sort((a: number, b: number) => b - a)[0]
        );
        return Array.from({ length: end - start + 1 }, (_, i) =>
          (start + i).toString().padStart(2, "0")
        );
      });

    const activePrice = this.filters.activeFilters.find(
      (filter) => filter.type === FilterType.PRICE
    );

    const pairsCopy = [...this.pairs];

    return pairsCopy.filter((pair) => {
      const isAirlineMatch =
        activeAirlines.length === 0 ||
        activeAirlines.includes(pair.arrival.flight_option?.airline.name ?? "");

      const depStopsLength =
        pair.departure.fare_option.segments.slice(1).length;
      const arrStopsLength = pair.arrival.fare_option.segments.slice(1).length;

      const isStopsMatch =
        activeStops.length === 0 ||
        activeStops.includes(depStopsLength) ||
        activeStops.includes(arrStopsLength) ||
        depStopsLength > 2 ||
        arrStopsLength > 2;

      const flightDepartureHour = TimeUtility.parsedFlightTimeorDate(
        pair.departure.flight_option?.departure_time ?? "",
        "HH"
      );
      const flightArrivalHour = TimeUtility.parsedFlightTimeorDate(
        pair.arrival.flight_option?.departure_time ?? "",
        "HH"
      );

      const isTimeMatch =
        activeTimes.length === 0 ||
        (activeTimes.includes(flightDepartureHour) &&
          activeTimes.includes(flightArrivalHour));

      const isPriceMatch =
        !activePrice ||
        getGrossFare(pair.arrival.fare_option) <= activePrice.count;

      return isAirlineMatch && isStopsMatch && isTimeMatch && isPriceMatch;
    });
  }
  get keepPolling(): boolean {
    return this.pollingService.keepPolling;
  }

  public async initiateBooking(
    tokens: string[],
    financialProfileId: string,
    callback: BookingInitiatedCallback
  ): Promise<void> {
    this.isBooking = true;

    const onSuccessCallback = (bookingId: string) => callback(bookingId);

    const onErrorCallback = () => callback(null);

    await this.flightsService.initiateBooking(
      {
        pre_booking_tokens: tokens,
        financial_profile_id: financialProfileId,
      },
      onSuccessCallback,
      onErrorCallback
    );

    this.isBooking = false;
  }

  public async getFlightRules(pre_booking_token: string): Promise<void> {
    const fareRules = this.flightsFareRules.get(pre_booking_token);

    if (!fareRules) {
      const onSuccessCallback = (response: FlightRulesResponse) => {
        this.flightsFareRules.set(pre_booking_token, response);
      };

      await this.flightsService.getFlightRules(
        { pre_booking_token },
        onSuccessCallback
      );
    }
  }

  public async startPolling(): Promise<void> {
    this.cancelPolling();

    await this.pollingService.cancelCurrentPolling(async () => {
      this.isLoading = true;

      await this.pollingService.startPolling((pollResults) => {
        this.internalEmit(pollResults);
      });

      this.isLoading = false;
    });
  }

  public refreshFiltersData(): void {
    this.getAirlinesFilterCount();
  }

  public getNextLeg(): void {
    if (this.isNextLegAvailable) {
      this.currentLegIndex += 1;
    }

    this.getFlights();
  }

  public getLeg(index: number): void {
    this.currentLegIndex = index;

    this.getFlights();
  }

  public updateSearchQuery(queryData: QueryData): void {
    this.searchQuery = new SearchQuery(queryData);
  }

  public cancelPolling(): void {
    this.pollingService.cancelPolling = true;

    this.resetData();
  }

  private resetData(): void {
    this.journeyLegs = [];

    this.flights = [];
    this.pairs = [];

    this.minPrice = 0;
    this.maxPrice = 10;
    this.currentLegIndex = 0;

    this.selectedFares = [];
    this.isLoading = true;
    this.isBooking = false;
    this.isPairView = false;

    this.pollingService.updateQuery(this.searchQuery, (context) =>
      this.flightsService.flights(context.query)
    );
  }

  private internalEmit(result: FlightResponse | null): void {
    if (!result) return;

    this.journeyLegs = result.journey_legs ?? [];

    this.getFlights();

    if (this.isReturnFlight) {
      this.getPairs();

      if (this.pairs.length > 0) {
        this.isLoading = false;
      }
    } else {
      if (this.flights.length > 0) {
        this.isLoading = false;
      }
    }

    this.searchQuery.updatePollId(result.poll_id ?? "");
  }

  private getFlights(): void {
    const legsLength = this.journeyLegs.length - 1;

    if (this.currentLegIndex <= legsLength) {
      this.flights = [
        ...(this.journeyLegs[this.currentLegIndex].flight_options ?? []),
      ];

      if (this.currentLegIndex != legsLength) {
        this.filterByEmptyFareOptionsIds();
      }

      if (this.currentLegIndex > 0) {
        this.filterByFareOptionsIds();
      }

      this.getAirlinesFilterCount();
      this.getFlightPriceRange();
    }
  }

  private getPairs(): void {
    const newPairs: ReturnFlightPair[] = [];

    if (this.journeyLegs.length > 1) {
      const departureFlights = this.journeyLegs[0];
      const departureFlightsOptions = departureFlights.flight_options;
      const departureFlightsFareOptions: FareOption[] =
        departureFlightsOptions.reduce(
          (accumulator: FareOption[], currentValue) => {
            return accumulator.concat(currentValue.fare_options);
          },
          []
        );

      const arrivalFlights = this.journeyLegs[1];
      const arrivalFlightsOptions = arrivalFlights.flight_options;
      const arrivalFlightsFareOptions: FareOption[] =
        arrivalFlightsOptions.reduce(
          (accumulator: FareOption[], currentValue) => {
            return accumulator.concat(currentValue.fare_options);
          },
          []
        );

      for (
        let departureFlightFareIndex = 0;
        departureFlightFareIndex < departureFlightsFareOptions.length;
        departureFlightFareIndex++
      ) {
        for (
          let arrivalFlightFareIndex = 0;
          arrivalFlightFareIndex < arrivalFlightsFareOptions.length;
          arrivalFlightFareIndex++
        ) {
          const departureFlightsFareOption =
            departureFlightsFareOptions[departureFlightFareIndex];
          const arrivalFlightsFareOption =
            arrivalFlightsFareOptions[arrivalFlightFareIndex];

          const departureNextFareOptionIds =
            departureFlightsFareOption.next_fare_option_ids;

          const arrivalPreBookingId =
            arrivalFlightsFareOption.pre_booking_token.split("_")[0];

          const departureFlightOption = departureFlightsOptions.find((option) =>
            option.fare_options.includes(departureFlightsFareOption)
          );
          const arrivalFlightOption = arrivalFlightsOptions.find((option) =>
            option.fare_options.includes(arrivalFlightsFareOption)
          );

          if (departureNextFareOptionIds.includes(arrivalPreBookingId)) {
            newPairs.push({
              departure: {
                flight_option: departureFlightOption,
                fare_option: departureFlightsFareOption,
              },
              arrival: {
                flight_option: arrivalFlightOption,
                fare_option: arrivalFlightsFareOption,
              },
            });
          }
        }
      }
    }

    this.pairs = newPairs;
  }

  private getAirlinesFilterCount(): void {
    this.filters.clearAirlines();

    let flightOptions: FlightOption[] = [];

    if (this.isReturnFlight && this.isPairView) {
      flightOptions =
        (this.pairs
          .map((pair) => pair.arrival.flight_option)
          .filter((option) => option !== undefined) as FlightOption[]) ?? [];
    } else {
      flightOptions = this.flights ?? [];
    }

    const airlineFilters: Record<string, Filter> = {};

    flightOptions.forEach((option) => {
      const airlineCode = option.airline.code;

      if (!airlineFilters[airlineCode]) {
        airlineFilters[airlineCode] = {
          label: option.airline.name,
          value: false,
          count: 0,
          type: FilterType.AIRLINES,
        };
      }

      airlineFilters[airlineCode].count++;
    });

    Object.values(airlineFilters).forEach(({ type, label, value, count }) => {
      this.filters.addFilter(type, label, value, count);
    });
  }

  private getFlightPriceRange(): void {
    let fares = this.flights.flatMap((flight) =>
      flight.fare_options.map((option) => getGrossFare(option))
    );

    fares = fares.sort((a, b) => a - b);

    this.minPrice = fares[0] ?? 0;
    this.maxPrice = fares[fares.length - 1] + 10 ?? 10;
  }

  private filterByFareOptionsIds(): void {
    if (
      (this.isReturnFlight || this.isMultiCityFlight) &&
      this.selectedFares.length > 0
    ) {
      const selectedFare = this.selectedFares[this.selectedFares.length - 1];

      const filteredFlights = this.flights
        .map((flight) => {
          const filteredFareOptions = flight.fare_options.filter(
            (fareOption) => {
              const [tokenId] = fareOption.pre_booking_token.split("_");
              return selectedFare.next_fare_option_ids.includes(tokenId);
            }
          );

          return {
            ...flight,
            fare_options: filteredFareOptions,
          };
        })
        .filter((flight) => flight.fare_options.length > 0);

      this.flights = filteredFlights;
    }
  }

  private filterByEmptyFareOptionsIds(): void {
    if (this.isReturnFlight || this.isMultiCityFlight) {
      const filteredFlights = this.flights
        .map((flight) => {
          const filteredFareOptions = flight.fare_options.filter(
            (fareOption) => fareOption.next_fare_option_ids.length > 0
          );

          return {
            ...flight,
            fare_options: filteredFareOptions,
          };
        })
        .filter((flight) => flight.fare_options.length > 0);

      this.flights = filteredFlights;
    }
  }
}
