<template>
  <MDialog
    title="Link Financial Profile"
    v-model="isMdialogOpen"
    class="link-financial-profile m-scrollbar"
    max-width="650"
  >
    <template #content>
      <div class="link-financial-profile-content">
        <MFinancialProfileCombobox
          v-model:inputValue="selectedFinancialProfile"
          label="Financial Profiles"
          itemValue="value"
          itemLabel="label"
          :options="financialProfileOptions"
          :disabled="isFinancialProfileFetching"
        />
        <MCombobox
          v-model:inputValue="piaProvider"
          label="PIA Provider"
          itemValue="value"
          itemLabel="label"
          :options="piaProvidersOptions"
          :hasError="!!piaProviderError"
          errorMessage="Required"
          name="piaProvider"
        />
        <MCombobox
          v-model:inputValue="airblueProvider"
          label="Airblue Provider"
          itemValue="value"
          itemLabel="label"
          :options="airblueProvidersOptions"
          :hasError="!!airblueProviderError"
          errorMessage="Required"
          name="airblueProvider"
        />
      </div>

      <template v-if="showConfirmCheck">
        <hr />
        <ag-checkbox
          :testId="
            genTestId(
              ELEMENT_TYPES.CHECK_BOX,
              'link-financial-profile-modal-isConfirmed'
            )
          "
          v-model="isConfirmed"
          :label="isConfirmedLabel"
        />
      </template>
    </template>
    <template #actions>
      <MButton :disabled="getLinkBtnDisabled" @click="onLink">Link </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LinkOrganizationDto } from "@/modules/FinancialProfile/dtos/linkOrganization.dto";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import {
  MFinancialProfileCombobox,
  MCombobox,
  MButton,
} from "@aeroglobe/ag-core-ui";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { ComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";

export default defineComponent({
  name: "LinkFinancialProfileModal",
  components: { MFinancialProfileCombobox, MCombobox },
  beforeMount() {
    this.$store.dispatch("fetchFinancialProfiles", {
      plan: ["PRO", "LITE", "STANDARD", "UMRAH"],
    });
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ELEMENT_TYPES,
      selectedFinancialProfile: "",
      isConfirmed: false,
      isConfirmedLabel:
        "This financial profile is already linked with another organization. Are you sure you want to overwrite?",
      showConfirmCheck: false,
      piaProvider: "",
      airblueProvider: "",
      piaProviderError: false,
      airblueProviderError: false,
      isMdialogOpen: this.isOpen,
    };
  },
  watch: {
    isOpen(newVal) {
      this.isMdialogOpen = newVal;
    },
    isMdialogOpen(newVal) {
      if (!newVal) {
        this.$emit("toggle");
      }
    },
  },
  computed: {
    financialProfileOptions(): FPComboboxOptions[] {
      const financialProfiles = this.$store.getters
        .financialProfiles as IFinancialProfile[];

      if (!financialProfiles) {
        return [];
      }

      return financialProfiles.map((fp: IFinancialProfile) => {
        const planType = fp.plan_name
          ?.split(" ")[1]
          ?.toLowerCase() as FPComboboxOptions["type"];
        const sector = fp.sector?.replace(/^Aeroglobe\s*-\s*/, "");

        return {
          id: fp.platform_id,
          label: fp.financial_profile_name,
          value: fp.public_id,
          isActive: fp.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp.status,
          sector: sector ?? "",
          type: planType,
        };
      });
    },
    getLinkBtnDisabled(): boolean {
      return (
        !this.selectedFinancialProfile ||
        this.$store.getters.isLinkingFinancialProfile
      );
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    piaProvidersOptions(): ComboboxOptions[] {
      const providers = this.$store.getters.PIAProviders || [];
      return providers.map((x: string) => ({ label: x, value: x }));
    },
    airblueProvidersOptions(): ComboboxOptions[] {
      const providers = this.$store.getters.airblueProviders || [];
      return providers.map((x: string) => ({ label: x, value: x }));
    },
  },
  methods: {
    genTestId,
    toggleModal() {
      this.$emit("toggle");
    },
    enableConfirmationCheckBox() {
      this.showConfirmCheck = true;
    },
    onLink() {
      this.piaProviderError = !this.piaProvider;
      this.airblueProviderError = !this.airblueProvider;

      if (this.piaProviderError || this.airblueProviderError) {
        return;
      }
      const body: LinkOrganizationDto = {
        organization: this.organizationId,
        confirmed: this.isConfirmed,
        pia_provider: this.piaProvider,
        airblue_provider: this.airblueProvider,
      };

      this.$store.dispatch("linkFinancialProfileWithOrganization", {
        body,
        params: {
          financial_data: "show",
        },
        failureCallback: this.enableConfirmationCheckBox,
        successCallback: this.toggleModal,
        financialProfileId: this.selectedFinancialProfile,
      });
    },
  },
});
</script>

<style lang="css">
@media screen and (min-height: 960px) and (min-width: 960px) {
  .link-financial-profile,
  .link-financial-profile .m-dialog-content {
    overflow: visible;
  }
}
.link-financial-profile-content {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
