<template>
  <div class="fit-hero-img"></div>
  <MCard>
    <div class="search-bar">
      <MFitCombobox
        v-model:input-value="destination"
        label="Where"
        itemValue="value"
        itemLabel="label"
        label-icon="m-cancel"
        :options="destinations"
        prepend-icon="m-hotel"
        placeholder="Destination"
        :error-message="destinationError"
        :has-error="!!destinationError"
        @update:inputValue="onDestinationSelect"
        :is-loading="false"
        class="fit-destination"
      />
      <MDatePicker
        label="Check In"
        :startDate="checkInMinDate"
        :min-date="new Date()"
        @onRangeDateChange="changeCheckInDate"
        placeholder="Check In Date"
        dateType="startDate"
        :multiCalendar="false"
        :hasRange="false"
        :error="checkInError"
        class="fit-dates"
      />
      <MDatePicker
        label="Check Out"
        :startDate="checkoutMinDate()"
        :min-date="checkoutMinDate()"
        @onRangeDateChange="changeCheckOutDate"
        placeholder="Check Out Date"
        dateType="endDate"
        :multiCalendar="false"
        :hasRange="false"
        :error="checkOutError"
        class="fit-dates"
      />
      <MTravelerCountBox
        label="Guests"
        prepend-icon="m-traveler-count-icon"
        :adult-count="passengerCount"
        :child-count="0"
        :infant-count="0"
        class="fit-traveler-combobox"
      >
        <template #items>
          <MTravelerCountBoxItem
            name="adultCount"
            label="Adult"
            icon="m-adult-traveler-icon"
            hint="(12 years and above)"
            :count="passengerCount"
            @update:adultCount="updatePassengerCount"
          />
        </template>
      </MTravelerCountBox>
    </div>
    <div class="search-button">
      <MButton @click="onSearchHotelsClick" :disabled="isFetchingFitHotels">
        Search Hotels</MButton
      >
      <MButton
        class="reset-button"
        @click="onResetHotelClick"
        type="outlined"
        v-if="hasEmailItem || hasPreviewItem || hasFitHotelData"
      >
        Reset
      </MButton>
    </div>
  </MCard>

  <MCard v-if="hasEmailItem" class="preview-items">
    <div v-for="(booking, bookingKey) in emailItems" :key="bookingKey">
      <FITHotelSelectedInfo
        :checkinDate="booking.check_in"
        :checkoutDate="booking.check_out"
        :noOfNight="booking.nights"
        :totalCost="booking.total_selling_price"
        :hotelName="booking.hotel_name"
        :roomType="booking.room_name"
        :travelers="booking.total_passengers"
      >
        <template #deleteFIT>
          <MFabButton
            class="delete-icon"
            icon="m-delete"
            @click="handleDelete(booking)"
          />
        </template>
      </FITHotelSelectedInfo>
    </div>
    <div class="email-button">
      <MButton type="outlined" @click="sendFITEmailOnPreview()">
        Continue by sending email
      </MButton>
    </div>
  </MCard>

  <MCard class="quick-search" v-show="!hasFitHotelData && showQuickSearch">
    <MTypography type="headline">Quick Search</MTypography>
    <MTypography type="label">
      Showing results based on portal's top destination
    </MTypography>
    <div class="m-card-quick-search">
      <FitQuickSearch
        v-for="(item, index) in destinations"
        :key="index"
        :country-name="item?.display_name"
        :country-thumb="item?.image"
        :description="item?.description"
        class="quick-search-item"
        @click="onFITQuickSearchClick(item)"
      />
    </div>
  </MCard>

  <div v-if="hasFitHotelData && !isFetchingFitHotels">
    <div v-for="hotel in fitHotelData.hotels_detail" :key="hotel.hotel_id">
      <MCard class="fit-hotel-detail">
        <ag-hotel-info
          :hotelName="hotel.hotel_name"
          :hotelDistance="hotel.hotel_meta.distance"
          :cityName="hotel.address"
        >
          <div class="hotel-info-card">
            <ag-hotel-info-card
              v-for="room in hotel.rooms_detail"
              :key="room.room_id"
              :singleBedRates="
                room.room_rate.per_night_per_bed + ' / Per Night'
              "
              :doubleBedRates="
                room.room_rate.per_night_per_room + ' / Per Night'
              "
              :quantity="room.room_name + ' x' + room.room_rate.room_count"
              :price="hotel.hotel_currency + ' ' + room.room_rate.final_price"
              :stay="room.total_nights + ' Night(s)'"
              class="item"
            >
              <MButton @click="openBookRoomModal(room, hotel)"
                >Book Room</MButton
              >
            </ag-hotel-info-card>
          </div>
        </ag-hotel-info>
      </MCard>
    </div>
  </div>

  <PackageDetailModal
    @closeModal="isPreviewModalOpen = false"
    :isOpen="isPreviewModalOpen"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MFitCombobox from "../components/MFitCombobox.vue";
import { addDays, format } from "date-fns";
import {
  FITSearchPayload,
  Destination,
  HotelsData,
  ItineraryInfo,
  RoomDetail,
  HotelDetail,
  FITBookingPayload,
} from "../constants";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import FitQuickSearch from "../components/FitQuickSearch.vue";
import PackageDetailModal from "../components/PackageDetailModal.vue";
import FITHotelSelectedInfo from "../components/SelectedHotelInfo.vue";

export default defineComponent({
  name: "UmrahHotelsSearch",
  components: {
    MFitCombobox,
    FitQuickSearch,
    PackageDetailModal,
    FITHotelSelectedInfo,
  },
  data() {
    return {
      destination: "",
      destinationError: "",
      checkIn: new Date(),
      checkOut: new Date(),
      checkInError: "",
      checkOutError: "",
      cityId: 1,
      passengerCount: 1,
      showQuickSearch: true,
      isPreviewModalOpen: false,
      destinations: [
        {
          display_name: "Madina",
          sub_display_name: "Saudia",
          city_id: 1,
          description:
            "Madina is one of the holiest cities in Islam, second only to Mecca. It holds immense religious significance as the city where Prophet Muhammad (PBUH) lived and is home to the Prophet's Mosque. Every year, millions of pilgrims visit Madina as part of the Hajj and Umrah pilgrimages, seeking spiritual connection and blessings.",
          image:
            "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmadina.jpeg?alt=media&token=a1fc4356-9803-4972-b198-207e85d7694f",
        },
        {
          display_name: "Mecca",
          sub_display_name: "Saudia",
          city_id: 2,
          description:
            "Mecca is the holiest city in Islam and the birthplace of Prophet Muhammad (PBUH). It is home to the Kaaba, located in the Sacred Mosque (Masjid al-Haram), the focal point of the Islamic faith toward which Muslims worldwide direct their prayers. Each year, millions of pilgrims from around the globe travel to Makkah to perform Hajj and Umrah.",
          image:
            "https://firebasestorage.googleapis.com/v0/b/aeroglobe-frontend.appspot.com/o/Hotel%20Images%2Fmakkah.jpeg?alt=media&token=1ee8bafa-7960-4f1f-a128-5a2382fd7f8d",
        },
      ] as Destination[],
    };
  },
  created() {
    this.checkIn = new Date();
    this.checkOut = this.checkoutMinDate();
    this.showQuickSearch = true;
  },
  methods: {
    async sendFITEmailOnPreview() {
      const payload: FITBookingPayload = {
        itinerary_info: this.emailItems,
        request_message: "FIT Inquiry",
      };
      const confirmFITHotel = await this.$store.dispatch(
        "confirmFITHotels",
        payload
      );

      if (confirmFITHotel) {
        this.$emit("closeModal");
      }
      this.$store.commit("resetFITItems");
    },
    updatePassengerCount(value: number): void {
      this.passengerCount = value;
    },
    handleDateFormat(date: Date) {
      return format(new Date(date), FORMAT_YYY_MM_DD);
    },
    openBookRoomModal(room: RoomDetail, hotel: HotelDetail) {
      this.isPreviewModalOpen = true;
      const previewDetails: ItineraryInfo = {
        hotel_id: hotel.hotel_id,
        room_id: room.room_id,
        total_passengers: this.passengerCount,
        check_in: this.handleDateFormat(this.checkIn),
        check_out: this.handleDateFormat(this.checkOut),
        total_selling_price: room.room_rate.final_price,
        number_of_rooms: 1,
        hotel_name: hotel.hotel_name,
        room_name: room.room_name,
        nights: room.total_nights,
      };
      this.$store.commit("setPreviewItems", previewDetails);
    },
    clearErrors() {
      this.checkInError = "";
      this.checkOutError = "";
      this.destinationError = "";
    },
    validate() {
      this.clearErrors();
      let hasError = false;
      if (this.checkIn >= this.checkOut) {
        this.checkInError =
          "The checkout date cannot be before or the same as the check-in date.";
        this.checkOutError =
          "The checkout date cannot be before or the same as the check-in date.";
        hasError = true;
      }
      if (this.destination === "") {
        this.destinationError = "destination is required";
        hasError = true;
      }
      return hasError;
    },
    onSearchHotelsClick() {
      const hasError = this.validate();
      const payload: FITSearchPayload = {
        city: this.cityId,
        checkin: this.handleDateFormat(this.checkIn),
        checkout: this.handleDateFormat(this.checkOut),
        travelers: this.passengerCount,
      };
      if (!hasError) {
        this.$store.dispatch("getFITHotelDetails", payload);
      }
    },
    onResetHotelClick() {
      this.$store.commit("resetFITItems");
    },
    onDestinationSelect(destination: Destination) {
      this.cityId = destination.city_id;
    },
    changeCheckInDate(newDate: Date) {
      this.checkIn = newDate;
    },
    changeCheckOutDate(newDate: Date) {
      this.checkOut = newDate;
    },
    checkoutMinDate(): Date {
      const minDate = addDays(new Date(this.checkIn), 1);
      return minDate;
    },
    onFITQuickSearchClick(item: Destination) {
      const payload: FITSearchPayload = {
        city: item.city_id,
        checkin: this.handleDateFormat(this.checkIn),
        checkout: this.handleDateFormat(this.checkOut),
        travelers: 1,
      };
      this.$store.dispatch("getFITHotelDetails", payload);
    },
    handleDelete(itinerary: ItineraryInfo) {
      const emailItems = this.emailItems.filter(
        (item: ItineraryInfo) => item !== itinerary
      );
      this.$store.commit("clearFITEmailItems");
      emailItems.forEach((item) => {
        const previewDetails: ItineraryInfo = {
          hotel_id: item.hotel_id,
          room_id: item.room_id,
          total_passengers: this.passengerCount,
          check_in: this.handleDateFormat(this.checkIn),
          check_out: this.handleDateFormat(this.checkOut),
          total_selling_price: item.total_selling_price,
          number_of_rooms: 1,
          hotel_name: item.hotel_name,
          room_name: item.room_name,
          nights: item.nights,
        };

        this.$store.commit("addFITEmailItems", previewDetails);
      });
    },
  },
  computed: {
    hasEmailItem(): boolean {
      return this.$store.getters.emailItems;
    },
    isSendingFITEmail(): boolean {
      return this.$store.getters.isSendingFITEmail;
    },
    checkInMinDate(): Date {
      return new Date();
    },
    isFetchingFitHotels(): boolean {
      return this.$store.getters.isFetchingFitHotels;
    },
    fitHotelData(): HotelsData {
      return this.$store.getters.fitHotelData;
    },
    hasFitHotelData(): boolean {
      const data = this.$store.getters.fitHotelData;
      return data !== null ? true : false;
    },
    emailItems(): ItineraryInfo[] {
      return this.$store.getters.emailItems;
    },
    hasPreviewItem(): boolean {
      return this.$store.getters.previewItems;
    },
  },
});
</script>

<style lang="css" scoped>
.fit-hero-img {
  background-image: url("@/assets/FITArtWork.svg");
  width: 100%;
  height: 235px;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-bar {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.fit-destination {
  min-width: 300px;
}
.fit-dates {
  min-width: 280px;
}

@media (min-width: 1300px) {
  .search-bar {
    flex-wrap: nowrap;
  }
}
.search-button {
  display: flex;
  justify-content: center;
}
.email-button {
  display: flex;
  justify-content: flex-end;
}
.quick-search {
  margin-top: 10px;
}
.fit-hotel-detail {
  margin-top: 10px;
}
.hotel-info-card {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.book-now {
  float: right;
}
.item {
  flex: 1 1 calc(33.33% - 16px);
  box-sizing: border-box;
  padding: 0px;
}

@media (max-width: 768px) {
  .item {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .item {
    flex: 1 1 100%;
  }
}
.m-card-quick-search {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
}

.quick-search-item {
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 930px) {
  .m-card-quick-search {
    grid-template-columns: 1fr;
  }
}
.preview-items {
  margin-top: 10px;
}
.fit-send-email {
  float: right;
}
.reset-button {
  margin-left: 10px;
}
</style>
