import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79994563"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-options" }
const _hoisted_2 = { class: "dc-title" }
const _hoisted_3 = {
  key: 1,
  class: "dc-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates, (option, index) => {
      return (_openBlock(), _createBlock(_component_MCard, {
        key: index,
        class: _normalizeClass(['date-option-card', { active: option.active }]),
        onClick: ($event: any) => (_ctx.onSelectDateHandler(option))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formatDate(option.departureDate)), 1),
          (option.arrivalDate)
            ? (_openBlock(), _createBlock(_component_MIcon, {
                key: 0,
                name: "m-round-trip-icon",
                class: "dc-icon",
                width: "16",
                height: "16"
              }))
            : _createCommentVNode("", true),
          (option.arrivalDate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.formatDate(option.arrivalDate)), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["class", "onClick"]))
    }), 128))
  ]))
}