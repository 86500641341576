<template>
  <AGAppContainer class="default-page-color" fullWidth>
    <a-g-full-width-page>
      <template v-slot:content-area>
        <transition name="fade" mode="out-in">
          <AGContainer class="login_wrapper" v-if="!IsSignUpSuccessful">
            <AGPanel>
              <AGLogo is-small />
              <h5>Welcome to Aeroglobe</h5>
              <p>Sign up now to prepare for your very own travel agency!</p>
              <form :onsubmit="onSubmit">
                <AgRow>
                  <AgColumn
                    class="col-height-85"
                    md="6"
                    lg="6"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <MTextfield
                      v-model:inputValue="agency_name"
                      label="Agency name"
                      :hasError="!!errors?.agency_name"
                      :errorMessage="errors?.agency_name"
                    />
                  </AgColumn>
                  <AgColumn
                    class="col-height-85"
                    md="6"
                    lg="6"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <MTextfield
                      v-model:inputValue="username"
                      label="Your name"
                      :hasError="!!errors?.username"
                      :errorMessage="errors?.username"
                    />
                  </AgColumn>
                  <AgColumn
                    class="col-height-85"
                    md="6"
                    lg="6"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <MTextfield
                      v-model:inputValue="email"
                      label="Email"
                      :hasError="!!errors?.email"
                      type="email"
                      :errorMessage="errors?.email"
                    />
                  </AgColumn>
                  <AgColumn
                    class="col-height-85"
                    md="6"
                    lg="6"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <MCombobox
                      v-model:inputValue="currency"
                      label="Currency"
                      itemValue="value"
                      itemLabel="label"
                      :options="currencies"
                    />
                  </AgColumn>

                  <AgColumn
                    class="col-height-85"
                    md="6"
                    lg="6"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <MCombobox
                      v-model:inputValue="country"
                      label="Country"
                      itemValue="value"
                      itemLabel="label"
                      :options="countries"
                    />
                  </AgColumn>
                  <AgColumn
                    class="col-height-85"
                    md="6"
                    lg="6"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <MCombobox
                      v-model:inputValue="city"
                      label="City"
                      itemValue="value"
                      itemLabel="label"
                      :options="PKCities"
                    />
                  </AgColumn>
                  <AgColumn
                    class="col-height-85"
                    md="12"
                    lg="12"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <div class="phone_field_container">
                      <AgPhoneField
                        test-id=""
                        default-country-code="PK"
                        info-message=""
                        :error="errors?.mobile_number"
                        @update-value="(value:any) => contact_number = value"
                      />
                    </div>
                  </AgColumn>
                  <div class="terms_container">
                    <ag-checkbox
                      label="I agree on"
                      v-model="isAgreedOnTermsAndConditions"
                    />
                    <span
                      @click="handleTermsAndConditionClick"
                      class="terms_and_conditions"
                    >
                      Terms and Conditions
                    </span>
                  </div>
                  <AgColumn
                    class="col-height-85"
                    md="12"
                    lg="12"
                    sm="12"
                    xs="12"
                    cols="12"
                  >
                    <div class="d-flex">
                      <MButton
                        :disabled="!isAgreedOnTermsAndConditions || isLoading"
                        behaviour="submit"
                        class="submit-btn"
                        variant="primary"
                        >Signup</MButton
                      >
                    </div>
                    <div>
                      <MTypography class="footer-caption" type="label">
                        <span>
                          Already have an account ?
                          <span>
                            <RouterLink class="footer-caption-link" to="/login">
                              Log In
                            </RouterLink>
                          </span>
                        </span>
                      </MTypography>
                    </div>
                  </AgColumn>
                </AgRow>
              </form>
            </AGPanel>
          </AGContainer>
          <SignupSuccessScreen v-else></SignupSuccessScreen>
        </transition>
      </template>
    </a-g-full-width-page>
  </AGAppContainer>
  <TermsAndConditionDialog
    @onTermsNotAgree="handleTermsNotAgree"
    @onTermsAgree="handleTermsAgree"
    :is-open="isTermsDialogOpen"
    @handleClose="handleCloseDialog"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { ValidationError } from "yup";
import { signupFormValidationSchema } from "./../validations/signupForm.validation";
import SignupSuccessScreen from "../components/SignupSuccess.vue";
import { PKCities } from "../constants/allPkCities";
import { PATH } from "@/ag-portal-common/constants/path";
import TermsAndConditionDialog from "./../components/termsAndCondtionDialog.vue";
import {
  MButton,
  MTextfield,
  MCombobox,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import { AUTH_ACTIONS } from "../vuex/actions";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "SignupView",
  components: {
    SignupSuccessScreen,
    TermsAndConditionDialog,
    MButton,
    MTextfield,
    MCombobox,
    MTypography,
    RouterLink,
  },
  data(): {
    PKCities: IObject[];
    countries: IObject[];
    currencies: IObject[];
    agency_name: string;
    contact_number: any;
    username: string;
    currency: string;
    country: string;
    city: string;
    email: string;
    errors: any;
    IsSignUpSuccessful: boolean;
    isAgreedOnTermsAndConditions: boolean;
    isTermsDialogOpen: boolean;
  } {
    return {
      PKCities,
      countries: [{ label: "Pakistan", value: "PK" }],
      currencies: [{ label: "PKR", value: "PKR" }],
      agency_name: "",
      contact_number: {},
      username: "",
      currency: "PKR",
      country: "PK",
      email: "",
      city: "",
      IsSignUpSuccessful: false,
      isAgreedOnTermsAndConditions: false,
      isTermsDialogOpen: false,
      errors: {},
    };
  },
  created() {
    this.onPageTitleUpdateHandler();
  },
  methods: {
    genTestId,
    successCallBack() {
      this.IsSignUpSuccessful = true;
      this.agency_name = "";
      this.contact_number = {};
      this.username = "";
      this.currency = "PKR";
      this.country = "PK";
      this.email = "";
    },
    async onSubmit(e: any) {
      e.preventDefault();
      this.errors = {};
      try {
        const contact_number = this.contact_number;
        const mobile_number = contact_number?.e164;
        const payload = {
          name: this.agency_name,
          contact_person_phone: mobile_number,
          contact_person_email: this.email,
          is_delete: false,
          is_active: true,
          currency: this.currency,
          username: this.username,
          country: this.country,
          city: this.city,
        };

        await signupFormValidationSchema.validate(
          {
            mobile_number: mobile_number,
            username: this.username,
            email: this.email,
            agency_name: this.agency_name,
            isValid: contact_number?.isValid,
          },
          { abortEarly: false }
        );
        this.$store.dispatch(AUTH_ACTIONS.SIGNUP, {
          payload,
          callback: this.successCallBack,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const err = yupValidationErrorAsSchema(ex);
          this.errors = err;
        }
      }
    },
    handleTermsAgree() {
      this.isAgreedOnTermsAndConditions = true;
      this.isTermsDialogOpen = false;
    },
    handleTermsNotAgree() {
      this.isAgreedOnTermsAndConditions = false;
      this.isTermsDialogOpen = false;
    },
    handleTermsAndConditionClick() {
      this.isTermsDialogOpen = true;
    },
    handleCloseDialog() {
      this.isTermsDialogOpen = false;
    },

    onPageTitleUpdateHandler() {
      document.title = "SignUp - Aeroglobe";
    },
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isAuthenticating;
    },
    PATH() {
      return PATH;
    },
  },
  mounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "auto";
    }
  },
  unmounted() {
    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      htmlElement.style.overflow = "hidden";
    }
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

header {
  line-height: 1.5;
  max-height: 100vh;
}

.phone_field_container {
  margin-bottom: 30px;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

.error-container {
  color: red;
  margin: 10px 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

.wrapper {
  padding-left: 24px !important;
}

.login_box {
  margin: auto !important;
  width: 600px;
}
.col-height-85 {
  max-height: 95px;
}

@media only screen and (max-width: 767px) {
  .login_box {
    margin: auto !important;
    width: 330px;
  }
  .terms_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.terms_and_conditions {
  color: blue;
  cursor: pointer;
  margin-left: 5px;
}

.terms_and_conditions:hover {
  text-decoration: underline;
}

.terms_container {
  display: flex;
  align-items: center;
}
.submit-btn {
  width: 100%;
}

.login_wrapper {
  .footer-caption {
    margin-top: 20px;
    text-align: center;
  }

  .footer-caption-link {
    color: var(--green-color);
  }
}
</style>
