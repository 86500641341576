<template>
  <div class="pv-fare-options">
    <div class="pv-fare-option">
      <div class="pv-fo-title">
        <b>Departure</b>
      </div>
      <div class="pv-fo-title">{{ getRbdNameHandler(trDepOption.rbd) }}</div>
      <div class="pv-fo-info">
        <MIcon
          class="pv-icon primary"
          :name="`${trDepOption.has_meal ? 'm-meal' : 'm-no-meal'}`"
        />
        <MDivider type="vertical" />

        <MIcon
          class="pv-icon primary"
          :name="`${trDepOption.baggage_info ? 'm-baggage' : 'm-no-baggage'}`"
        />

        <div>
          {{ getBaggageInfo(trDepOption.baggage_info) }}
        </div>
      </div>
      <div class="pv-fo-actions">
        <template v-if="trArrOption.price.ag_deal_discount">
          <div class="pv-fo-deal-code">
            {{ trArrOption.discount_data.discount_code }}
          </div>
          <div class="pv-fo-discounted-price">
            {{ getFormattedCurrency(trArrOption.price.gross_fare.value) }}
          </div>
        </template>

        <div class="pv-fo-price">
          {{ calculateFlightPrice(trArrOption) }}
        </div>

        <MMenuWrapper @close:menu="trArrOption.showInfo = false">
          <template #target>
            <MIcon
              class="pv-icon pv-fo-details"
              name="m-info"
              @click="trArrOption.showInfo = !trArrOption.showInfo"
            />
          </template>

          <template #menu>
            <MCard
              class="pv-fo-menu m-index-level-two"
              v-show="trArrOption.showInfo"
            >
              <div class="pv-fo-title">RBD’s Pricing Details</div>
              <div class="pv-fo-code">
                {{ getRbdNameHandler(trDepOption.rbd) }} -
                {{ getRbdNameHandler(trArrOption.rbd) }}
              </div>

              <div class="pv-fo-summary">
                <div class="pv-fo-row">
                  <div class="title">Base Fare</div>
                  <div class="price">
                    {{
                      getFormattedCurrency(trArrOption.price.base_fare.value)
                    }}
                  </div>
                </div>
                <div class="pv-fo-row">
                  <div class="title">Tax</div>
                  <div class="price">
                    {{ getFormattedCurrency(trArrOption.price.tax.value) }}
                  </div>
                </div>
                <div class="pv-fo-row">
                  <div class="title">Gross Fare</div>
                  <div class="price">
                    {{
                      getFormattedCurrency(trArrOption.price.gross_fare.value)
                    }}
                  </div>
                </div>
                <div
                  class="pv-fo-row"
                  v-if="trArrOption.price.ag_price_difference"
                >
                  <div class="title">
                    {{
                      getPriceDifferenceLabel(
                        trArrOption.price.ag_price_difference.value
                      )
                    }}
                  </div>
                  <div class="price">
                    {{
                      getFormattedCurrency(
                        trArrOption.price.ag_price_difference.value
                      )
                    }}
                  </div>
                </div>
                <div
                  class="pv-fo-row"
                  v-if="trArrOption.price.ag_deal_discount"
                >
                  <div class="title">Deal Discount</div>
                  <div class="price">
                    {{
                      getFormattedCurrency(
                        trArrOption.price.ag_deal_discount.value
                      )
                    }}
                  </div>
                </div>
                <div
                  class="pv-fo-row"
                  v-if="getFareCalculatorDifference(trArrOption).amount !== 0"
                >
                  <div class="title">
                    {{ getFareCalculatorDifference(trArrOption).label }}
                  </div>
                  <div class="price">
                    {{ getFareCalculatorDifference(trArrOption).formatted }}
                  </div>
                </div>

                <MDivider class="pv-fo-divider" />

                <div class="pv-fo-row total">
                  <div class="title">Total</div>
                  <div class="price">
                    {{ calculateFlightPrice(trArrOption) }}
                  </div>
                </div>
              </div>
            </MCard>
          </template>
        </MMenuWrapper>
      </div>
    </div>

    <div class="pv-fare-option">
      <div class="pv-fo-title">
        <b>Return</b>
      </div>
      <div class="pv-fo-title">{{ getRbdNameHandler(trArrOption.rbd) }}</div>
      <div class="pv-fo-info">
        <MIcon
          class="pv-icon primary"
          :name="`${trArrOption.has_meal ? 'm-meal' : 'm-no-meal'}`"
        />
        <MDivider type="vertical" />

        <MIcon
          class="pv-icon primary"
          :name="`${trArrOption.baggage_info ? 'm-baggage' : 'm-no-baggage'}`"
        />

        <div>
          {{ getBaggageInfo(trArrOption.baggage_info) }}
        </div>
      </div>
      <div class="pv-fo-actions">
        <MButton
          class="pv-fo-book-fare"
          dense
          @click="onSelectFareHandler(trArrOption)"
        >
          Book Fare
        </MButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MIcon, MDivider, MButton, MMenuWrapper } from "@aeroglobe/ag-core-ui";

import { BaggageInfo, FareOption, Price } from "@/ag-flight-components/types";
import UTILS from "@/ag-portal-common/utils";
import { TemperFare, TransformedFareOption } from "../types";

export default defineComponent({
  name: "SingleFareOptions",
  components: {
    MIcon,
    MDivider,
    MButton,
    MMenuWrapper,
  },
  props: {
    departureFareOption: {
      type: Object as PropType<FareOption>,
      required: true,
    },
    arrivalFareOption: {
      type: Object as PropType<FareOption>,
      required: true,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
  },
  data() {
    return {
      trDepOption: {
        ...this.departureFareOption,
        showInfo: false,
      } as TransformedFareOption,
      trArrOption: {
        ...this.arrivalFareOption,
        showInfo: false,
      } as TransformedFareOption,
    };
  },
  emits: ["select:fare"],
  methods: {
    calculateFlightPrice(fareOption: FareOption): string {
      const calculatedPrice = UTILS.calculateFlightPrice(
        fareOption,
        this.temperFare
      );

      return this.getFormattedCurrency(calculatedPrice);
    },
    getBaggageInfo(baggageInfo?: BaggageInfo | null): string {
      return UTILS.getBaggageInfo(baggageInfo);
    },
    getFormattedCurrency(amount: number): string {
      return UTILS.getFormattedCurrency(amount);
    },
    getTotalAmount(price: Price): number {
      let amount = 0;

      if (price.ag_gross_fare && price.ag_gross_fare.value) {
        amount = price.ag_gross_fare.value;
      } else {
        amount = price.gross_fare.value;
      }

      return amount;
    },
    getFareCalculatorDifference(fareOption: FareOption): {
      label: string;
      amount: number;
      formatted: string;
    } {
      const fcAmonut = UTILS.calculateFlightPrice(fareOption, this.temperFare);
      const totalAmount = this.getTotalAmount(fareOption.price);
      const difference = fcAmonut - totalAmount;

      return {
        label: difference > 0 ? "FC Fees" : "FC Discount",
        amount: difference,
        formatted: this.getFormattedCurrency(difference),
      };
    },
    getPriceDifferenceLabel(amount?: number | null): string {
      if ((amount ?? 0) > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onSelectFareHandler(fareOption: FareOption): void {
      this.$emit("select:fare", fareOption);
    },
  },
});
</script>

<style scoped lang="css">
.pv-icon {
  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.pv-icon.primary {
  color: var(--m-primary-color);
}

.pv-fare-options {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 8px 0;

  border: 1px solid var(--m-flight-card-border-color);
  border-radius: 0 0 8px 8px;
}

.pv-fare-options .pv-fare-option {
  display: grid;
  grid-template-columns: 140px 220px 140px 1fr;
  align-items: center;

  padding: 0 16px;

  font-size: 14px;
}

.pv-fare-options .pv-fare-option .pv-fo-info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.pv-fare-options .pv-fare-option .pv-fo-info .m-divider {
  height: 20px;
}

.pv-fare-options .pv-fare-option .pv-fo-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}

.pv-fare-options .pv-fare-option .pv-fo-deal-code {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 0 12px;

  font-size: 12px;
  font-weight: 500;

  color: var(--m-success-color);
  background-color: color-mix(
    in srgb,
    var(--m-success-color) 10%,
    var(--m-light-color) 100%
  );
  border-radius: 4px;
}

.pv-fare-options .pv-fare-option .pv-fo-discounted-price {
  color: var(--m-error-color);

  position: relative;
}
.pv-fare-options .pv-fare-option .pv-fo-discounted-price::before {
  content: " ";

  position: absolute;
  left: 0;
  top: 9px;

  width: 100%;
  height: 1px;

  background-color: var(--m-error-color);

  transform: rotate(-8deg);
}

.pv-fare-options .pv-fare-option .pv-fo-price {
  font-size: 16px;
  font-weight: 600;
}

.pv-fare-options .pv-fare-option .pv-fo-details {
  cursor: pointer;

  transition: color 0.2s ease-in-out;
}
.pv-fare-options .pv-fare-option .pv-fo-details:hover {
  color: var(--m-primary-color);
}

.pv-fare-options .pv-fare-option .pv-fo-book-fare {
  height: 24px;

  font-size: 12px;
  padding: 0 12px;
}

.pv-fare-options .pv-fare-option .pv-fo-menu {
  width: 260px;
  --m-menu-distance: calc(100% + 8px);
  top: var(--m-menu-distance);
  right: 0;

  position: absolute;
  box-shadow: var(--m-menu-shadow-color);

  font-size: 12px !important;

  display: flex;
  flex-direction: column;
  gap: 16px;
}
.pv-fare-options .pv-fare-option .pv-fo-menu .pv-fo-title {
  font-weight: 500;
}
.pv-fare-options .pv-fare-option .pv-fo-menu .pv-fo-code {
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;

  height: 24px;
  padding: 0 12px;

  font-size: 12px;
  font-weight: 500;

  color: var(--m-primary-color);
  background-color: color-mix(
    in srgb,
    var(--m-primary-color) 10%,
    var(--m-light-color) 100%
  );
  border-radius: 4px;
}
.pv-fare-options .pv-fare-option .pv-fo-menu .pv-fo-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.pv-fare-options .pv-fare-option .pv-fo-menu .pv-fo-summary .pv-fo-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pv-fare-options .pv-fare-option .pv-fo-menu .pv-fo-summary .pv-fo-row.total {
  font-weight: 500;
}
</style>
