<template>
  <template v-if="flightsLoading">
    <ag-loader />
  </template>
  <template v-else>
    <SearchedFlightInfo :trip-date="tripDate">
      <template #timer>
        <ag-row>
          <ag-column sm="12" md="12" cols="12">
            <Timer v-show="showTimer" @timer:timedOut="handleTimerTimeOut" />
          </ag-column>
          <ag-column sm="12" md="12" cols="12">
            <MCard
              class="selectedFinancialProfileCard"
              v-if="showSelectedFinancialProfileBox"
            >
              <div class="financial-profile-title-container">
                <span class="financial-profile-title"
                  >{{ selectedFinancialProfile?.financial_profile_name }} -
                  {{ selectedFinancialProfile?.platform_id }}</span
                >
                <div class="financial-profile-chip-container">
                  <MChip
                    class="status-chip"
                    :variant="
                      isSelectedFinancialProfileActive ? 'success' : 'error'
                    "
                  >
                    {{ selectedFinancialProfile?.status }}
                  </MChip>
                  <MChip
                    class="status-chip"
                    v-if="selectedFinancialProfile?.is_default"
                    variant="success"
                  >
                    DEFAULT
                  </MChip>
                </div>
              </div>
            </MCard>
          </ag-column>
        </ag-row>
      </template>
    </SearchedFlightInfo>

    <AgDiv
      class="date-chip-container"
      v-show="showAvailableDateCalendarContaner"
    >
      <AgAvailableDateChip
        v-for="(item, index) in futureDateCombinations"
        :key="index"
        :departureDate="item?.departureDate"
        :arrivalDate="item?.returnDate"
        :isReturn="isReturnType"
        @quickDateSearchEmit="hanldeDateChipClick"
        :isActive="isDateActive(item?.departureDate)"
      />
    </AgDiv>

    <Filters
      :selected-filters="selectedFilters"
      :handle-change-search-dialog-open="handleChangeSearchDialogOpen"
      :handle-update-range="handleUpdateRange"
      @updateSelectedFilters="updateSelectedFilters"
      :is-loading="isFlightsPollingLoading"
      :is-pair-view="isReturnType && isPairView"
      :return-flight-pairs-stops="returnFlightPairsStops"
    />

    <FlightSearchChips
      class="flight-filters"
      :selected-filters="selectedFilters"
      :handle-remove-chip="handleRemoveChip"
      :is-pair-view="isReturnType && isPairView"
    >
      <template #fare_calculator>
        <FareCalculator
          v-model:pair-view="isPairView"
          @update:pair-view="onPairViewToggleHandler"
        />
      </template>
    </FlightSearchChips>

    <!-- Selected Legs -->
    <SelectedFlightLegs />

    <!-- Polling -->
    <template v-if="isFlightsPollingLoading">
      <FlightsPollingLoader :poll-message="pollMessage" />
    </template>

    <!-- Flight List Mapping -->
    <div v-show="isReturnType && isPairView">
      <template
        v-for="(returnFlightPair, index) in filteredReturnFlightPairs"
        :key="`return-flight-${index}`"
      >
        <ReturnFlightDetailCardItem :returnFlightPair="returnFlightPair" />
      </template>
    </div>

    <div v-show="!(isReturnType && isPairView)">
      <template
        v-for="(flight, index) in filteredFlights"
        :key="`single-flight-${index}`"
      >
        <FlightDetailCardItem
          @selectedDialogItem="handleFlightFareSelect"
          :flightItem="flight"
        />
      </template>
    </div>

    <!-- Polling -->
    <template v-if="isPolling && !isPollingLimitReached">
      <template v-if="isFlightsLoaded">
        <FlightsPollingLoader :poll-message="pollMessage" />
      </template>
      <template v-else>
        <MCard class="flights-loader">
          <div class="word skeleton">header</div>
          <div class="word skeleton">description content</div>
          <div class="line skeleton"></div>
          <div class="line skeleton"></div>
        </MCard>
      </template>
    </template>

    <template
      v-else-if="
        emptyResults ||
        (isReturnType
          ? filteredReturnFlightPairs.length === 0
          : filteredFlights.length === 0)
      "
    >
      <!-- Results no found -->
      <AgNotFound
        test-id=""
        heading="No Results Found"
        description="Please Try Modify Your Filters OR Try Again"
      />
    </template>
  </template>

  <!-- Dialogs -->
  <template v-if="selectedFlightItemForDialog && selectedFareForDialog">
    <FareBookingDialog
      :is-open="isBookingDialogOpen"
      :flight-item="selectedFlightItemForDialog"
      :fare-option="selectedFareForDialog"
      @handleDialogClose="handleCloseBookingDialog"
    />
  </template>
  <FlightChangeSearch
    :is-open="isChangeSearchDialogOpen"
    :handle-close="handleChangeSearchDialogClose"
  />
  <ResultTimeoutModal
    :is-open="timerTimeOuted"
    :handle-refresh-results="handleRefreshResults"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";
import {
  // differenceInDays,
  addDays,
  subDays,
  format,
  isSameDay,
  isPast,
} from "date-fns";

// Components
import SearchedFlightInfo from "@/ag-flight-components/components/FlightSearchResults/SearchedFlightInfo.vue";
import Filters from "@/ag-flight-components/components/FlightSearchResults/Filters.vue";
import FlightSearchChips from "@/ag-flight-components/components/FlightSearchResults/FlightSearchChips.vue";
import SelectedFlightLegs from "@/ag-flight-components/components/FlightSearchResults/SelectedFlightLegs.vue";
import FlightChangeSearch from "@/ag-flight-components/components/FlightSearchResults/ChangeSearch.vue";
import Timer from "@/ag-flight-components/components/FlightSearchResults/Timer.vue";
import ResultTimeoutModal from "@/ag-flight-components/components/FlightSearchResults/TimeoutModal.vue";
import FareCalculator from "@/ag-flight-components/components/FlightSearchResults/FareCalculator.vue";
import FlightDetailCardItem from "@/ag-flight-components/components/FlightSearchResults/FlightDetailCardItem.vue";
import ReturnFlightDetailCardItem from "@/ag-flight-components/components/FlightSearchResults/ReturnFlightDetailCardItem.vue";
import FareBookingDialog from "@/ag-flight-components/components/FlightSearchResults/FareBookingDialog.vue";
import FlightsPollingLoader from "@/components/FlightsPollingLoader.vue";

// Utils
import {
  formatMultiCityQueryParamToArray,
  formatTripDates,
  filterFlightsByAirlines,
  filterFlightsByDepartureTime,
  filterFlightsByStops,
  filterFlightsByPrice,
  getGrossFare,
  getAirlineFilters,
  calculatePriceRangeFilters,
} from "@/ag-flight-components/utils";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { POLL_MESSAGES } from "@/modules/Flight/constants/polling";

// Types
import {
  FareOption,
  FlightOption,
  SelectedFlightSearchQueryFilters,
  SelectedFlightLeg,
  FlightResponse,
  ReturnFlightPair,
  FlightFilters,
  ReturnFlightPairStop,
  FlightAirlneBaseFilter,
} from "@/ag-flight-components/types/Flights";
import {
  MAX_POLLING_TIMEOUT,
  MAX_POLLING_LIMIT,
} from "@/modules/Flight/constants";
import { JourneyLeg } from "@/ag-flight-components/types/JourneyLeg";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { PATH } from "@/ag-portal-common/constants/path";
import { QuickDateChip } from "@/ag-flight-components/types/QuickDateChip";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/analytic.service";
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import { IProfilePreferences } from "@/ag-portal-common/interfaces/profile.interface";

import { MCard } from "@aeroglobe/ag-core-ui";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import UTILS from "@/ag-portal-common/utils";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { flightStopsFilters } from "@/ag-flight-components/constants/flightStopFilters";

interface FlightSearchResultsInterface {
  filteredFlights: Array<FlightOption>;
  tripDate: string;
  timerTimeOuted: boolean;
  selectedFilters: SelectedFlightSearchQueryFilters;
  isBookingDialogOpen: boolean;
  currentSelectedFlightOptionForDialog: FlightOption | null;
  selectedFareForDialog: FareOption | null;
  selectedFlightItemForDialog: FlightOption | null;
  isChangeSearchDialogOpen: boolean;
  allowRouterQueryRequest: boolean;
  isPairView: boolean;
  pollingInterval: number;
  maxPollingLimit: number;
  pollingLimit: number;
  timeoutId: number;
  isPollingLimitReached: boolean;
  pollMessages: string[];
  pollMessageIndex: number;
  pollMessage: string;

  returnFlightPairs: ReturnFlightPair[];
  filteredReturnFlightPairs: ReturnFlightPair[];
}

export default defineComponent({
  name: "FlightSearchResult",
  components: {
    SearchedFlightInfo,
    Filters,
    FlightSearchChips,
    SelectedFlightLegs,
    FlightChangeSearch,
    Timer,
    ResultTimeoutModal,
    FareCalculator,
    FlightDetailCardItem,
    FareBookingDialog,
    FlightsPollingLoader,

    ReturnFlightDetailCardItem,

    MCard,
  },
  data(): FlightSearchResultsInterface {
    return {
      tripDate: "",
      timerTimeOuted: false,
      isBookingDialogOpen: false,
      isChangeSearchDialogOpen: false,
      selectedFareForDialog: null,
      selectedFlightItemForDialog: null,
      selectedFilters: {
        airlines: [],
        stops: [],
        rtStops: [],
        priceRange: [],
        departureTime: [],
        maxPriceRangeSlider: 0,
      },
      filteredFlights: [],
      currentSelectedFlightOptionForDialog: null,
      allowRouterQueryRequest: true,
      isPairView: true,
      pollingInterval: MAX_POLLING_TIMEOUT,
      maxPollingLimit: MAX_POLLING_LIMIT,
      pollingLimit: 0,
      timeoutId: 0,
      isPollingLimitReached: false,
      pollMessages: POLL_MESSAGES,
      pollMessageIndex: 0,
      pollMessage: "",

      returnFlightPairs: [],
      filteredReturnFlightPairs: [],
    };
  },

  computed: {
    isPolling(): boolean {
      return this.$store.getters.isPolling;
    },
    isFlightsPollingLoading(): boolean {
      return (
        this.isPolling && !this.isPollingLimitReached && this.isFlightsLoaded
      );
    },
    journeyLegs(): Array<JourneyLeg> {
      return this.$store.getters.journeyLegs;
    },

    flights(): FlightOption[] {
      return this.$store.getters.flights;
    },
    isFlightsLoading(): boolean {
      return this.$store.getters.isFlightsLoading;
    },
    isFlightsLoaded(): boolean {
      const flightsLength = this.flights ? this.flights.length > 0 : false;
      const returnFlightsLength = this.returnFlightPairs
        ? this.returnFlightPairs.length > 0
        : false;

      return this.isReturnType && this.isPairView
        ? returnFlightsLength
        : flightsLength;
    },
    flightsResponse(): FlightResponse | undefined {
      return this.$store.getters.flightsResponse;
    },

    isReturnType(): boolean {
      return ROUTE_TYPE.RETURN === this.$route.query?.route_type;
    },

    emptyResults(): boolean {
      const isLoading = this.$store.getters.isFlightsLoading;
      const flights = this.$store.getters.flights;
      return !isLoading && flights.length < 1;
    },

    savedFlightModePreferences(): boolean {
      const savedPreferences = localStorage.getItem("user-preferences");

      if (savedPreferences) {
        const parsedPreferences: IProfilePreferences =
          JSON.parse(savedPreferences);

        return parsedPreferences.flight_search_pairs_view;
      }

      return true;
    },

    nextJourneyLeg(): JourneyLeg {
      return this.$store.getters.nextJourneyLeg;
    },
    flightsLoading(): boolean {
      return this.$store.getters.isFlightsLoading;
    },
    filters(): FlightFilters {
      return this.$store.getters.filters;
    },
    currentJourneyLeg(): JourneyLeg {
      return this.$store.getters.currentJourneyLeg;
    },
    selectedFlightFilters(): SelectedFlightSearchQueryFilters {
      return this.$store.getters.allSelectdFlightFilters;
    },
    selectedFlightLegs(): SelectedFlightLeg[] {
      return this.$store.state.flightModule.selectedFlightLegs;
    },
    showTimer(): boolean {
      const isLoading = this.$store.getters.isFlightsLoading;
      const flights = this.$store.getters.flights;

      return !isLoading && flights.length > 0;
    },
    allJourneyLegs(): JourneyLeg {
      return this.$store.getters.allJourneyLegs;
    },
    showAvailableDateCalendarContaner(): boolean {
      const { route_type } = this.$route.query;

      return route_type !== ROUTE_TYPE.MULTI_CITY;
    },
    futureDateCombinations(): {
      departureDate: Date;
      returnDate?: Date;
    }[] {
      const { route_type, trips } = this.$route.query;
      const tripsString = trips?.toString() || "";

      const [, , departure_date, return_date] = tripsString.split(",");
      const dates = [];

      if (route_type === ROUTE_TYPE.RETURN) {
        const departureDate = new Date(departure_date);
        const returnDate = new Date(return_date);

        if (isSameDay(departureDate, returnDate)) {
          // If departure and return dates are the same, make dates incremental
          for (let i = 0; i < 7; i++) {
            dates.push({
              departureDate: addDays(departureDate, i),
              returnDate: addDays(returnDate, i),
            });
          }
        } else {
          // const daysDifference = differenceInDays(returnDate, departureDate);
          for (let i = 1; i < 4; i++) {
            // const totalDiff = daysDifference * (i + 1);
            dates.push({
              departureDate: subDays(departureDate, i),
              returnDate: subDays(returnDate, i),
            });
          }
          for (let i = 0; i < 4; i++) {
            // const totalDiff = daysDifference * (i + 1);
            dates.push({
              departureDate: addDays(departureDate, i),
              returnDate: addDays(returnDate, i),
            });
          }

          dates.sort((a, b) => {
            const dateA: Date = a.departureDate as Date;
            const dateB: Date = b.departureDate as Date;
            return dateA.getTime() - dateB.getTime();
          });

          const currentDate = new Date();
          const filteredDates = dates.filter((date) => {
            // Extract only the date part without the time component for date.departureDate
            const dateWithoutTime = new Date(
              date.departureDate.getFullYear(),
              date.departureDate.getMonth(),
              date.departureDate.getDate()
            );

            // Extract only the date part without the time component for the current date
            const currentDateWithoutTime = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate()
            );
            return dateWithoutTime >= currentDateWithoutTime;
          });

          return filteredDates;
        }
      } else if (route_type === ROUTE_TYPE.ONEWAY) {
        const departureDate = new Date(departure_date);

        if (isSameDay(departureDate, new Date())) {
          // If it's the same day, show the next 8 days
          for (let i = 0; i < 7; i++) {
            dates.push({
              departureDate: addDays(departureDate, i + 1),
            });
          }
        } else if (isPast(departureDate)) {
          // if past then show aaj k din sa aagay k 8 days
          for (let i = 0; i < 7; i++) {
            dates.push({
              departureDate: addDays(new Date(), i + 1),
            });
          }
        } else {
          // If it's not the same day, show the previous 3 days and the next 3 days
          // const daysDifference = differenceInDays(departureDate, new Date());
          // const previousDays = Math.min(daysDifference, 4);

          for (let i = 1; i < 4; i++) {
            dates.push({
              departureDate: subDays(departureDate, i),
            });
          }
          for (let i = 0; i < 4; i++) {
            dates.push({
              departureDate: addDays(departureDate, i),
            });
          }
        }

        dates.sort((a, b) => {
          const dateA: Date = a.departureDate as Date;
          const dateB: Date = b.departureDate as Date;
          return dateA.getTime() - dateB.getTime();
        });

        const currentDate = new Date();
        const filteredDates = dates.filter((date) => {
          // Extract only the date part without the time component for date.departureDate
          const dateWithoutTime = new Date(
            date.departureDate.getFullYear(),
            date.departureDate.getMonth(),
            date.departureDate.getDate()
          );

          // Extract only the date part without the time component for the current date
          const currentDateWithoutTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );
          return dateWithoutTime >= currentDateWithoutTime;
        });

        return filteredDates;
      }

      return dates;
    },
    isSuperUserOrOperationUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS);
    },
    isAGSuperUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    selectedFinancialProfile(): IFinancialProfile {
      const { fp } = this.$route.query;
      const financialProfiles = this.$store.getters.issuanceFinancialProfiles;
      const selectedFP: IFinancialProfile = financialProfiles?.find(
        (x: IFinancialProfile) => x?.public_id === fp
      );
      return selectedFP;
    },
    isSelectedFinancialProfileActive(): boolean {
      return (
        this.selectedFinancialProfile?.status === ORGANIZATION_STATUSES.ACTIVE
      );
    },
    returnFlightPairsStops(): ReturnFlightPairStop[] {
      const stopsMap = new Map<
        ReturnFlightPairStop["label"],
        ReturnFlightPairStop["value"]
      >();

      if (!this.isPairView) {
        return [];
      }

      this.returnFlightPairs.forEach((pair) => {
        const departureFareOptionsStops =
          pair.departure.fare_option.segments.length - 1;
        const arrivalFareOptionsStops =
          pair.arrival.fare_option.segments.length - 1;

        let stop = {
          label: "",
          value: [departureFareOptionsStops, arrivalFareOptionsStops],
        };

        if (departureFareOptionsStops === 0 && arrivalFareOptionsStops === 0) {
          stop.label = "Non Stop";
        } else {
          stop.label = `${departureFareOptionsStops} + ${arrivalFareOptionsStops} Stops`;
        }

        stopsMap.set(stop.label, stop.value);
      });

      stopsMap.values();

      const toArray = Array.from(stopsMap, ([label, value]) => ({
        label,
        value,
      }));

      return toArray;
    },
    showSelectedFinancialProfileBox(): boolean {
      return (
        this.isAGSuperUser &&
        !!this.selectedFinancialProfile?.organization_public_id
      );
    },
  },

  methods: {
    isDateActive(departureDate: Date) {
      const urlDate = this.$route.query.trips as string;
      urlDate?.toString() || "";

      const [, , departure_date] = urlDate.split(",");

      // Parse departure_date to match the format "2024-02-14"
      const formattedDepartureDate = new Date(departureDate)
        .toISOString()
        .slice(0, 10);

      if (departure_date === formattedDepartureDate) {
        return true;
      } else {
        return false;
      }
    },

    resetPolling() {
      this.pollingLimit = 0;
      this.pollMessage = "";
      this.pollMessageIndex = 0;
      clearTimeout(this.timeoutId);
    },
    handleTimerTimeOut(value: boolean) {
      this.timerTimeOuted = value;
    },
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration: (durationInNumber: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    getFirstHalf(str: string) {
      const indexOfUnderscore = str.indexOf("_");

      if (indexOfUnderscore !== -1) {
        return str.substring(0, indexOfUnderscore);
      } else {
        return str;
      }
    },
    getReturnFlightPairsAirlineCount(
      filters: FlightFilters
    ): FlightAirlneBaseFilter[] {
      const returnFlightOptions: FlightOption[] = this.returnFlightPairs
        .map((pair) => pair.arrival.flight_option)
        .filter(
          (flightOption): flightOption is FlightOption =>
            flightOption !== undefined
        );

      const rtAirlinesFilters = getAirlineFilters(returnFlightOptions);

      const newFilters = filters.airlines.map((airline) => {
        const rtTemp = rtAirlinesFilters.find(
          (rtAirline) => rtAirline.value === airline.value
        );
        const newFilter = {
          label: airline.label,
          value: airline.value,
          labelRt: rtTemp ? rtTemp.label : undefined,
        };
        return newFilter;
      });

      return newFilters;
    },
    initializeFilters(
      firstLegFlightOptions: FlightOption[],
      flightOptions: FlightOption[]
    ): FlightFilters {
      return {
        airlines: getAirlineFilters(
          firstLegFlightOptions
        ) as FlightAirlneBaseFilter[],
        stops: flightStopsFilters,
        rtStops: this.returnFlightPairsStops,
        priceRange: calculatePriceRangeFilters(flightOptions),
        departureTime: ["00-06", "06-12", "12-18", "18-24"],
      };
    },
    createSelectedFlightFilters(
      filters: FlightFilters
    ): SelectedFlightSearchQueryFilters {
      return {
        departureTime: ["00-06", "06-12", "12-18", "18-24"],
        airlines: filters.airlines.map((x) => x.value),
        stops: filters.stops.map((x) => x.value),
        rtStops: filters.rtStops.map((x) => x.value),
        priceRange: filters.priceRange,
        maxPriceRangeSlider: filters.priceRange[1],
      };
    },
    onUpdateFiltersHandler() {
      if (
        !this.flightsResponse ||
        this.flightsResponse.journey_legs.length === 0
      ) {
        return;
      }

      const firstJourneyLeg: JourneyLeg = this.flightsResponse.journey_legs[0];
      const secondJourneyLeg: JourneyLeg | undefined =
        this.flightsResponse.journey_legs[1];

      const flightOptions = secondJourneyLeg
        ? secondJourneyLeg.flight_options
        : firstJourneyLeg.flight_options;

      const filters = this.initializeFilters(
        firstJourneyLeg.flight_options,
        flightOptions
      );

      if (this.isPairView && this.isReturnType) {
        filters.airlines = this.getReturnFlightPairsAirlineCount(filters);
      }

      const selectedFlightFilters = this.createSelectedFlightFilters(filters);

      this.$store.commit("saveFilters", filters);
      this.$store.commit("saveSelectedFlightFilters", selectedFlightFilters);
    },
    async fetchFlights(poll_id?: string) {
      if (!poll_id) {
        this.$store.commit("clearSelectedFlightLegs");
        this.isPollingLimitReached = false;
      }

      const {
        route_type,
        adult,
        child,
        infant,
        cabin_class,
        from_chip,
        trips,
        fp,
      } = this.$route.query;
      const tripsString = trips?.toString() || "";

      const [origin, destination, departure_date, return_date] =
        tripsString.split(",");

      this.tripDate = formatTripDates(tripsString, route_type as string);

      const normalFlightPayload = {
        origin,
        destination,
        departure_date,
        return_date: return_date ? return_date : null,
      };

      const legs = formatMultiCityQueryParamToArray(trips as string);

      const multiCityFlightPayload = {
        legs,
      };

      const payload = {
        route_type: route_type,
        traveler_count: {
          adult_count: adult,
          child_count: child,
          infant_count: infant,
        },
        cabin_class: cabin_class,
        from_chip: from_chip,
        non_stop_flight: false,
        ...([ROUTE_TYPE.ONEWAY, ROUTE_TYPE.RETURN].includes(
          route_type as ROUTE_TYPE
        ) && {
          ...normalFlightPayload,
        }),
        ...(route_type === ROUTE_TYPE.MULTI_CITY && {
          ...multiCityFlightPayload,
        }),
        financial_profile_id: fp,
        poll_id,
      };

      await this.$store.dispatch("fetchFlights", payload);
      this.pollingLimit += 1;

      this.returnFlightPairs = this.onCreateReturnFlightPairHandler();

      this.filteredReturnFlightPairs = this.returnFlightPairs;

      this.onUpdateFiltersHandler();
    },
    onCreateReturnFlightPairHandler(): ReturnFlightPair[] {
      const returnFlightPairs: ReturnFlightPair[] = [];
      const journeyLegs: JourneyLeg[] = this.journeyLegs ?? [];

      if (journeyLegs.length > 1) {
        const departureFlights = journeyLegs[0];
        const departureFlightsOptions = departureFlights.flight_options;
        const departureFlightsFareOptions: FareOption[] =
          departureFlightsOptions.reduce(
            (accumulator: FareOption[], currentValue) => {
              return accumulator.concat(currentValue.fare_options);
            },
            []
          );

        const arrivalFlights = journeyLegs[1];
        const arrivalFlightsOptions = arrivalFlights.flight_options;
        const arrivalFlightsFareOptions: FareOption[] =
          arrivalFlightsOptions.reduce(
            (accumulator: FareOption[], currentValue) => {
              return accumulator.concat(currentValue.fare_options);
            },
            []
          );

        for (
          let departureFlightFareIndex = 0;
          departureFlightFareIndex < departureFlightsFareOptions.length;
          departureFlightFareIndex++
        ) {
          for (
            let arrivalFlightFareIndex = 0;
            arrivalFlightFareIndex < arrivalFlightsFareOptions.length;
            arrivalFlightFareIndex++
          ) {
            const departureFlightsFareOption =
              departureFlightsFareOptions[departureFlightFareIndex];
            const arrivalFlightsFareOption =
              arrivalFlightsFareOptions[arrivalFlightFareIndex];

            const departureNextFareOptionIds =
              departureFlightsFareOption.next_fare_option_ids;

            const arrivalPreBookingId =
              arrivalFlightsFareOption.pre_booking_token.split("_")[0];

            const departureFlightOption = departureFlightsOptions.find(
              (option) =>
                option.fare_options.includes(departureFlightsFareOption)
            );
            const arrivalFlightOption = arrivalFlightsOptions.find((option) =>
              option.fare_options.includes(arrivalFlightsFareOption)
            );

            if (departureNextFareOptionIds.includes(arrivalPreBookingId)) {
              returnFlightPairs.push({
                departure: {
                  flight_option: departureFlightOption,
                  fare_option: departureFlightsFareOption,
                },
                arrival: {
                  flight_option: arrivalFlightOption,
                  fare_option: arrivalFlightsFareOption,
                },
              });
            }
          }
        }
      }

      let filteredFlightPairs: ReturnFlightPair[] =
        this.sortReturnFlightsByPriceHandler(returnFlightPairs);

      return filteredFlightPairs;
    },
    sortReturnFlightsByPriceHandler(
      pairs: ReturnFlightPair[]
    ): ReturnFlightPair[] {
      const filteredFlightPairs: ReturnFlightPair[] = [...pairs].sort(
        (a: ReturnFlightPair, b: ReturnFlightPair) =>
          a.arrival.fare_option.price.gross_fare.value -
          b.arrival.fare_option.price.gross_fare.value
      );

      return filteredFlightPairs;
    },
    filterReturnFlightsByAirlinesHandler(
      pairs: ReturnFlightPair[],
      filter: SelectedFlightSearchQueryFilters
    ) {
      const filteredFlightPairs: ReturnFlightPair[] = [...pairs].filter(
        (pair) => {
          const flightOption = pair.arrival.flight_option;
          const airlineCode = flightOption ? flightOption.airline.code : "";

          return filter.airlines.includes(airlineCode);
        }
      );

      return filteredFlightPairs;
    },
    filterReturnFlightsByDepartureTimeHandler(
      pairs: ReturnFlightPair[],
      filter: SelectedFlightSearchQueryFilters
    ) {
      const departureHours = filter.departureTime.flatMap((time) => {
        const [start, end] = time.split("-").map(Number);
        return Array.from({ length: end - start + 1 }, (_, i) =>
          (start + i).toString().padStart(2, "0")
        );
      });

      const filteredFlightPairs: ReturnFlightPair[] = [...pairs].filter(
        (pair) => {
          const flightOption = pair.departure.flight_option;

          const flightDepartureHour = TimeUtility.parsedFlightTimeorDate(
            flightOption ? flightOption.departure_time : "",
            "HH"
          );

          return departureHours.includes(flightDepartureHour);
        }
      );

      return filteredFlightPairs;
    },
    filterReturnFlightsByStopsHandler(
      pairs: ReturnFlightPair[],
      filter: SelectedFlightSearchQueryFilters
    ) {
      const filteredFlightPairs: ReturnFlightPair[] = pairs.filter((pair) => {
        const departureStops = pair.departure.fare_option.segments.length - 1;
        const arrivalStops = pair.arrival.fare_option.segments.length - 1;

        return filter.rtStops.some(
          ([depStops, arrStops]) =>
            depStops === departureStops && arrStops === arrivalStops
        );
      });

      return filteredFlightPairs;
    },
    filterReturnFlightsByPriceHandler(
      pairs: ReturnFlightPair[],
      filter: SelectedFlightSearchQueryFilters
    ) {
      const minPriceRange = filter.priceRange[0];
      const maxPriceRange = filter.maxPriceRangeSlider ?? 0;

      const filteredFlightPairs: ReturnFlightPair[] = [...pairs].filter(
        (pair) => {
          const grossFare = getGrossFare(pair.arrival.fare_option);
          return grossFare >= minPriceRange && grossFare <= maxPriceRange;
        }
      );

      return filteredFlightPairs;
    },

    handleRefreshResults() {
      this.timerTimeOuted = false;
      this.fetchFlights();
    },
    handleFilters(
      journeyLeg: JourneyLeg,
      filter: SelectedFlightSearchQueryFilters
    ) {
      let tempFilteredData: Array<FlightOption> = this.filteredFlights;

      tempFilteredData = filterFlightsByAirlines(
        journeyLeg.flight_options,
        filter.airlines
      );
      tempFilteredData = filterFlightsByDepartureTime(
        tempFilteredData,
        filter.departureTime
      );
      tempFilteredData = filterFlightsByStops(tempFilteredData, filter.stops);
      tempFilteredData = filterFlightsByPrice(
        tempFilteredData,
        filter.priceRange[0],
        filter.maxPriceRangeSlider ?? 0
      );

      return tempFilteredData;
    },
    handleReturnFlightFilters(
      pairs: ReturnFlightPair[],
      filter: SelectedFlightSearchQueryFilters
    ) {
      let tempReturnFlightPairs: ReturnFlightPair[] = [...pairs];

      tempReturnFlightPairs = this.filterReturnFlightsByAirlinesHandler(
        tempReturnFlightPairs,
        filter
      );
      tempReturnFlightPairs = this.filterReturnFlightsByDepartureTimeHandler(
        tempReturnFlightPairs,
        filter
      );
      tempReturnFlightPairs = this.filterReturnFlightsByStopsHandler(
        tempReturnFlightPairs,
        filter
      );
      tempReturnFlightPairs = this.filterReturnFlightsByPriceHandler(
        tempReturnFlightPairs,
        filter
      );

      return tempReturnFlightPairs;
    },
    handleRemoveChip(
      index: number,
      filter: "airlines" | "stops" | "rtStops" | "departureTime" | "priceRange"
    ) {
      const selectedFilter = this.selectedFilters[filter];
      selectedFilter.splice(index, 1);
    },
    filterFlightsByFareOptionIds(
      flightOptions: FlightOption[],
      nextFareIds: Array<string>
    ): Array<FlightOption> {
      return flightOptions.filter((item) => {
        item.fare_options = item.fare_options.filter((fare_opt) => {
          return nextFareIds.includes(fare_opt.pre_booking_token.split("_")[0]);
        });

        return item.fare_options.length ? true : false;
      });
    },
    handleFlightFareSelect(fareOption: FareOption, flightOption: FlightOption) {
      this.selectedFareForDialog = fareOption;
      this.selectedFlightItemForDialog = flightOption;
    },
    handleCloseBookingDialog() {
      this.isBookingDialogOpen = false;
      this.selectedFareForDialog = null;
      this.selectedFlightItemForDialog = null;
    },
    handleUpdateRange(val: number) {
      this.selectedFilters.maxPriceRangeSlider = val;
    },
    handleChangeSearchDialogClose() {
      this.isChangeSearchDialogOpen = false;
    },
    handleChangeSearchDialogOpen() {
      this.isChangeSearchDialogOpen = true;
      this.allowRouterQueryRequest = true;
    },
    updateSelectedFilters(updatedFilters: SelectedFlightSearchQueryFilters) {
      this.selectedFilters = updatedFilters;
    },
    hanldeDateChipClick(data: QuickDateChip) {
      analyticsService.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.AG_QUICK_DATE_SEARCH_CHIP
      );
      this.allowRouterQueryRequest = true;
      const currentQueryParams = { ...this.$route.query };
      const splitedTripsQueryParam = (
        currentQueryParams?.trips as string
      ).split(",");
      if (data?.isReturn) {
        splitedTripsQueryParam[2] = format(
          data?.departureDate,
          FORMAT_YYY_MM_DD
        );
        splitedTripsQueryParam[3] = format(data?.arrivalDate, FORMAT_YYY_MM_DD);
      } else {
        splitedTripsQueryParam[2] = format(
          data?.departureDate,
          FORMAT_YYY_MM_DD
        );
      }

      currentQueryParams.trips = splitedTripsQueryParam.join(",");
      this.$router.push(
        `${PATH.FLIGHTS_SEARCH_RESULT}${formatQueryPath(currentQueryParams)}`
      );
    },
    getRandomTravelMessageHandler() {
      const index = this.pollMessageIndex;

      this.pollMessageIndex += 1;

      if (index >= this.pollMessages.length - 1) {
        this.pollMessageIndex = 0;
      }

      return this.pollMessages[index];
    },
    onPairViewToggleHandler(value: boolean) {
      this.isPairView = value;
    },
    onSyncPreferencesHandler() {
      this.isPairView = this.savedFlightModePreferences;
    },
  },

  watch: {
    flightsResponse: {
      handler: function (response: FlightResponse) {
        const keepPolling = response.keep_polling;
        const pollId = response.poll_id;

        if (
          keepPolling &&
          pollId &&
          this.isPolling &&
          !(this.pollingLimit === this.maxPollingLimit)
        ) {
          this.pollMessage = this.getRandomTravelMessageHandler();

          this.timeoutId = setTimeout(() => {
            this.fetchFlights(pollId);
          }, this.pollingInterval) as unknown as number;
        } else {
          this.isPollingLimitReached = true;
          this.resetPolling();
        }
      },
      deep: true,
    },

    selectedFilters: {
      handler: function (filter: SelectedFlightSearchQueryFilters) {
        if (!this.currentJourneyLeg) {
          return;
        }

        const filteredFlights = this.handleFilters(
          this.currentJourneyLeg,
          filter
        );
        this.filteredFlights = filteredFlights;

        if (this.isReturnType) {
          let filteredReturnFlightPairs = [...this.returnFlightPairs];

          filteredReturnFlightPairs = this.handleReturnFlightFilters(
            filteredReturnFlightPairs,
            filter
          );

          this.filteredReturnFlightPairs = filteredReturnFlightPairs;
        }
      },
      deep: true,
    },

    "$store.state.flightModule.selectedFlightFilters": {
      handler: function (selectedFilters) {
        this.selectedFilters = _.cloneDeep(selectedFilters);
      },
    },

    "$store.state.flightModule.selectedFares": {
      handler: function (fares: FareOption[]) {
        const { currentLegIndex, lastLegIndex, flights } =
          this.$store.state.flightModule;

        if (fares.length) {
          const fare = fares[fares.length - 1];
          const allJourneyLegs = this.$store.getters.allJourneyLegs;

          if (currentLegIndex < lastLegIndex) {
            const nextLegFareIds = fare.next_fare_option_ids;

            const nextLeg = _.cloneDeep(
              allJourneyLegs[currentLegIndex + 1] as JourneyLeg
            );

            if (nextLegFareIds.length) {
              const nextLegFlights = this.filterFlightsByFareOptionIds(
                nextLeg.flight_options,
                nextLegFareIds
              );

              this.$store.commit("saveFilteredLegsByFareIds", nextLegFlights);

              this.filteredFlights = nextLegFlights;
            } else {
              this.filteredFlights = nextLeg.flight_options;
            }
            this.$store.commit("saveCurrentLegIndex", currentLegIndex + 1);
          }
        } else {
          this.filteredFlights = flights;
        }
      },
      deep: true,
    },

    selectedFareForDialog: {
      handler: function (newVal, oldVal) {
        const newItem = _.cloneDeep(newVal);
        const oldItem = _.cloneDeep(oldVal);

        if (newItem && newItem !== oldItem) {
          this.isBookingDialogOpen = true;
        } else {
          this.isBookingDialogOpen = false;
        }
      },
    },

    "$route.query": {
      handler: function () {
        if (this.allowRouterQueryRequest) {
          this.fetchFlights();
          this.allowRouterQueryRequest = false;
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$store.dispatch("fetchAirports", { location: "Pakistan" });
  },
  unmounted() {
    this.$store.commit("clearSelectedFlightLegs");
    this.resetPolling();
  },
  beforeRouteUpdate() {
    this.$store.commit("clearSelectedFlightLegs");
    this.resetPolling();
  },
  beforeMount() {
    this.$store.commit("clearSelectedFlightLegs");
  },
});
</script>

<style>
.flight-filters .fare_calculator_main .flight_dropdown {
  height: min-content !important;
}
</style>

<style scoped lang="scss">
.date-chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;
}

.flights-loader .line,
.flights-loader .word {
  width: fit-content;
  position: relative;
  height: 24px;
  background: #d9d9d980;
  color: transparent;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
}

.flights-loader .line {
  width: 100%;
}

.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: skeleton-loading 1.5s infinite;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  transform: translateX(-100%);
  z-index: 1;
}

@keyframes skeleton-loading {
  100% {
    transform: translateX(100%);
  }
}

.financial-profile-title-container {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
}
.financial-profile-chip-container {
  display: flex;
  gap: 10px;
}
.financial-profile-title {
  font-size: 15px;
  margin-right: 20px;
  font-weight: 500;
  text-align: start;
  @media (max-width: 767px) {
    text-align: center;
  }
}
.status-chip {
  font-weight: 600;
}

.selectedFinancialProfileCard {
  border: solid 1px var(--green-color);
}
</style>
