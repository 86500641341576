<template>
  <div class="date-options">
    <template v-for="(option, index) in dates" :key="index">
      <MCard
        :class="['date-option-card', { active: option.active }]"
        @click="onSelectDateHandler(option)"
      >
        <div class="dc-title">
          {{ formatDate(option.departureDate) }}
        </div>
        <MIcon
          v-if="option.arrivalDate"
          name="m-round-trip-icon"
          class="dc-icon"
          width="16"
          height="16"
        />
        <div class="dc-title" v-if="option.arrivalDate">
          {{ formatDate(option.arrivalDate) }}
        </div>
      </MCard>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MCard, MIcon } from "@aeroglobe/ag-core-ui";
import { DateOption } from "../types";
import { format } from "date-fns";

export default defineComponent({
  name: "DateOptionsCard",
  props: {
    dates: {
      type: Array as PropType<DateOption[]>,
      required: true,
    },
  },
  emits: ["select:date"],
  components: { MCard, MIcon },
  methods: {
    onSelectDateHandler(option: DateOption): void {
      if (option.active) {
        return;
      }

      const type = "yyyy-MM-dd";
      const dateOption: DateOption = {
        departureDate: this.formatDate(option.departureDate, type),
        ...(option.arrivalDate && {
          arrivalDate: this.formatDate(option.arrivalDate, type),
        }),
        active: option.active,
      };

      this.$emit("select:date", dateOption);
    },
    formatDate(date: string, type = "EEE, dd MMM"): string {
      const convertedToDate = new Date(date);

      return format(convertedToDate, type);
    },
  },
});
</script>

<style scoped lang="css">
.date-options {
  width: 100%;

  display: flex;
  gap: 12px;

  align-items: center;
  justify-content: center;
}

.date-options .date-option-card {
  color: var(--m-secondary-color);

  padding: 10px;

  font-size: 14px;
  line-height: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: box-shadow 0.1s ease-in-out;
  user-select: none;
}

.date-options .date-option-card.active {
  background-color: color-mix(
    in srgb,
    var(--m-primary-color) 15%,
    var(--m-light-color) 100%
  );
}
.date-options .date-option-card:hover {
  box-shadow: 0 0 4px var(--m-divider-color);
}
.date-options .date-option-card:active {
  box-shadow: none;
}

.date-options .date-option-card .dc-icon {
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--m-primary-color);
}
</style>
