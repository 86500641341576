<template>
  <div class="sf-fare-options">
    <div
      class="sf-fare-option"
      v-for="(option, index) in transformedFareOptions"
      :key="index"
    >
      <div class="sf-fo-title">{{ getRbdNameHandler(option.rbd) }}</div>
      <div class="sf-fo-info">
        <MIcon
          class="sf-icon primary"
          :name="`${option.has_meal ? 'm-meal' : 'm-no-meal'}`"
        />
        <MDivider type="vertical" />

        <MIcon class="sf-icon primary" :name="`${renderBaggageIcon(option)}`" />

        <div>{{ getBaggageInfo(option.baggage_info) }}</div>
      </div>
      <div class="sf-fo-actions">
        <template v-if="option.price.ag_deal_discount">
          <div class="sf-fo-deal-code">
            {{ option.discount_data.discount_code }}
          </div>
          <div class="sf-fo-discounted-price">
            {{ getFormattedCurrency(option.price.gross_fare.value) }}
          </div>
        </template>

        <div class="sf-fo-price">
          {{ calculateFlightPrice(option) }}
        </div>

        <MMenuWrapper @close:menu="option.showInfo = false">
          <template #target>
            <MIcon
              class="sf-icon sf-fo-details"
              name="m-info"
              @click="option.showInfo = !option.showInfo"
            />
          </template>

          <template #menu>
            <MCard
              class="sf-fo-menu m-index-level-two"
              v-show="option.showInfo"
            >
              <div class="sf-fo-title">Pricing Details</div>
              <div class="sf-fo-code">{{ getRbdNameHandler(option.rbd) }}</div>

              <div class="sf-fo-summary">
                <div class="sf-fo-row">
                  <div class="title">Base Fare</div>
                  <div class="price">
                    {{ getFormattedCurrency(option.price.base_fare.value) }}
                  </div>
                </div>
                <div class="sf-fo-row">
                  <div class="title">Tax</div>
                  <div class="price">
                    {{ getFormattedCurrency(option.price.tax.value) }}
                  </div>
                </div>
                <div class="sf-fo-row">
                  <div class="title">Gross Fare</div>
                  <div class="price">
                    {{ getFormattedCurrency(option.price.gross_fare.value) }}
                  </div>
                </div>
                <div class="sf-fo-row" v-if="option.price.ag_price_difference">
                  <div class="title">
                    {{
                      getPriceDifferenceLabel(
                        option.price.ag_price_difference.value
                      )
                    }}
                  </div>
                  <div class="price">
                    {{
                      getFormattedCurrency(
                        option.price.ag_price_difference.value
                      )
                    }}
                  </div>
                </div>
                <div class="sf-fo-row" v-if="option.price.ag_deal_discount">
                  <div class="title">Deal Discount</div>
                  <div class="price">
                    {{
                      getFormattedCurrency(option.price.ag_deal_discount.value)
                    }}
                  </div>
                </div>
                <div
                  class="sf-fo-row"
                  v-if="getFareCalculatorDifference(option).amount !== 0"
                >
                  <div class="title">
                    {{ getFareCalculatorDifference(option).label }}
                  </div>
                  <div class="price">
                    {{ getFareCalculatorDifference(option).formatted }}
                  </div>
                </div>

                <MDivider class="sf-fo-divider" />

                <div class="sf-fo-row total">
                  <div class="title">Total</div>
                  <div class="price">{{ calculateFlightPrice(option) }}</div>
                </div>
              </div>
            </MCard>
          </template>
        </MMenuWrapper>

        <MButton
          class="sf-fo-book-fare"
          dense
          @click="onSelectFareHandler(option)"
        >
          Book Fare
        </MButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MIcon, MDivider, MButton, MMenuWrapper } from "@aeroglobe/ag-core-ui";

import { BaggageInfo, FareOption, Price } from "@/ag-flight-components/types";
import UTILS from "@/ag-portal-common/utils";
import { TemperFare, TransformedFareOption } from "../types";

export default defineComponent({
  name: "SingleFareOptions",
  components: {
    MIcon,
    MDivider,
    MButton,
    MMenuWrapper,
  },
  props: {
    fareOptions: {
      type: Array as PropType<FareOption[]>,
      required: true,
    },
    temperFare: {
      type: Object as PropType<TemperFare>,
      required: true,
    },
  },
  data() {
    return {
      transformedFareOptions: this.fareOptions.map((option) => ({
        ...option,
        showInfo: false,
      })) as TransformedFareOption[],
    };
  },
  emits: ["select:fare"],
  methods: {
    calculateFlightPrice(fareOption: FareOption): string {
      const calculatedPrice = UTILS.calculateFlightPrice(
        fareOption,
        this.temperFare
      );

      return this.getFormattedCurrency(calculatedPrice);
    },
    getBaggageInfo(baggageInfo?: BaggageInfo | null): string {
      return UTILS.getBaggageInfo(baggageInfo);
    },
    getFormattedCurrency(amount: number): string {
      return UTILS.getFormattedCurrency(amount);
    },
    getTotalAmount(price: Price): number {
      let amount = 0;

      if (price.ag_gross_fare && price.ag_gross_fare.value) {
        amount = price.ag_gross_fare.value;
      } else {
        amount = price.gross_fare.value;
      }

      return amount;
    },
    getFareCalculatorDifference(fareOption: FareOption): {
      label: string;
      amount: number;
      formatted: string;
    } {
      const fcAmonut = UTILS.calculateFlightPrice(fareOption, this.temperFare);
      const totalAmount = this.getTotalAmount(fareOption.price);
      const difference = fcAmonut - totalAmount;

      return {
        label: difference > 0 ? "FC Fees" : "FC Discount",
        amount: difference,
        formatted: this.getFormattedCurrency(difference),
      };
    },
    getPriceDifferenceLabel(amount?: number | null): string {
      if ((amount ?? 0) > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    getRbdNameHandler(name: string): string {
      return name.length === 1 ? `RDB: ${name}` : name;
    },
    onSelectFareHandler(fareOption: FareOption): void {
      this.$emit("select:fare", fareOption);
    },
    renderBaggageIcon(option: TransformedFareOption) {
      if (option.baggage_info) {
        if (
          option.baggage_info?.pieces > 0 &&
          option.baggage_info?.weight > 0
        ) {
          return "m-baggage";
        }
      }

      return "m-no-baggage";
    },
  },
});
</script>

<style scoped lang="css">
.sf-icon {
  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.sf-icon.primary {
  color: var(--m-primary-color);
}

.sf-fare-options {
  border-top: 1px solid var(--m-flight-card-border-color);
}

.sf-fare-options .sf-fare-option {
  display: grid;
  grid-template-columns: 260px 200px 1fr;
  align-items: center;

  height: 48px;
  padding: 0 16px;

  border: 1px solid var(--m-flight-card-border-color);
  border-top: none;

  font-size: 14px;
}
.sf-fare-options .sf-fare-option:last-child {
  border-radius: 0 0 8px 8px;
}

.sf-fare-options .sf-fare-option .sf-fo-info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sf-fare-options .sf-fare-option .sf-fo-info .m-divider {
  height: 20px;
}

.sf-fare-options .sf-fare-option .sf-fo-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}

.sf-fare-options .sf-fare-option .sf-fo-deal-code {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 0 12px;

  font-size: 12px;
  font-weight: 500;

  color: var(--m-success-color);
  background-color: color-mix(
    in srgb,
    var(--m-success-color) 10%,
    var(--m-light-color) 100%
  );
  border-radius: 4px;
}

.sf-fare-options .sf-fare-option .sf-fo-discounted-price {
  color: var(--m-error-color);

  position: relative;
}
.sf-fare-options .sf-fare-option .sf-fo-discounted-price::before {
  content: " ";

  position: absolute;
  left: 0;
  top: 9px;

  width: 100%;
  height: 1px;

  background-color: var(--m-error-color);

  transform: rotate(-8deg);
}

.sf-fare-options .sf-fare-option .sf-fo-price {
  font-size: 16px;
  font-weight: 600;
}

.sf-fare-options .sf-fare-option .sf-fo-details {
  cursor: pointer;

  transition: color 0.2s ease-in-out;
}
.sf-fare-options .sf-fare-option .sf-fo-details:hover {
  color: var(--m-primary-color);
}

.sf-fare-options .sf-fare-option .sf-fo-book-fare {
  height: 24px;

  font-size: 12px;
  padding: 0 12px;
}

.sf-fare-options .sf-fare-option .sf-fo-menu {
  width: 260px;
  --m-menu-distance: calc(100% + 8px);
  top: var(--m-menu-distance);
  right: 0;

  position: absolute;
  box-shadow: var(--m-menu-shadow-color);

  font-size: 12px !important;

  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sf-fare-options .sf-fare-option .sf-fo-menu .sf-fo-title {
  font-weight: 500;
}
.sf-fare-options .sf-fare-option .sf-fo-menu .sf-fo-code {
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;

  height: 24px;
  padding: 0 12px;

  font-size: 12px;
  font-weight: 500;

  color: var(--m-primary-color);
  background-color: color-mix(
    in srgb,
    var(--m-primary-color) 10%,
    var(--m-light-color) 100%
  );
  border-radius: 4px;
}
.sf-fare-options .sf-fare-option .sf-fo-menu .sf-fo-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sf-fare-options .sf-fare-option .sf-fo-menu .sf-fo-summary .sf-fo-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sf-fare-options .sf-fare-option .sf-fo-menu .sf-fo-summary .sf-fo-row.total {
  font-weight: 500;
}
</style>
